import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ColumnDef,
  createColumnHelper,
  Row,
  SortingState
} from "@tanstack/react-table";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./Saved.module.scss";

import Condition from "../../../../components/shared/Condition";
import Icon from "../../../../components/shared/Icon";
import DatePickerModal from "../../../../components/shared/Modals/SetDatePickerModal";
import SavedUploadsFilterModal from "../../../../components/shared/Modals/SetSavedFilterModal";
import Chip, {
  IChip
} from "../../../../components/userManagement/Filter/chip/Chip";
import FilterBar from "../../../../components/userManagement/Filter/FilterBar";
import DeleteSelectedPopup from "../../../../components/userManagement/Modals/DeleteSelectedPopup";
import DeleteSuccessPopup from "../../../../components/userManagement/Modals/DeleteSuccessPopup";
import SelectCheckbox from "../../../../components/userManagement/Select&chekbox/SelectChekbox";
import Table from "../../../../components/userManagement/UploadTableV8";
import { rowSelectionOptions } from "../../../../components/userManagement/UploadTableV8/UploadTable.constants";
import { useActions } from "../../../../hooks/useActions";
import {
  generatedSavedUploadsFiltersSelector,
  modalSelector,
  savedUploadsDateRangeSelector,
  savedUploadsFiltersSelector
} from "../../../../store/architectUsersStore/selectors";
import { options } from "../../constants";
import useSaved from "../../hooks/useSaved";
import useUploadFile from "../../hooks/useUploadFile";
import {
  Document,
  FetchedBatches,
  PaginationParams,
  SavedBatch,
  TableType
} from "../../types";
import SelectTableType from "../SelectTableType";
import { TData } from "./Saved.types";

const Saved = () => {
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;
  const [tableType, setTableType] = useState<TableType>(
    initialTableType || "statement-doc"
  );
  const [savedBatches, setSavedBatches] = useState<FetchedBatches>();
  const [loading, setLoading] = useState<boolean>(true);
  const [savedBatchesPageSize, setSavedBatchesPageSize] = useState(10);
  const {
    SetOpenModalAction,
    SetSavedUploadsDateRange,
    SetSavedUploadsFilters,
    UploaderListAction,
    GenerateSavedUploadsFilter,
    SetUploadTableType
  } = useActions();
  const history = useHistory();
  const savedUploadsFilters = useSelector(savedUploadsFiltersSelector);
  const savedUploadsDateRange = useSelector(savedUploadsDateRangeSelector);
  const modal = useSelector(modalSelector);
  const generatedSavedUploadsFilters = useSelector(
    generatedSavedUploadsFiltersSelector
  );

  const { control } = useForm();

  const { getSavedBatches } = useSaved();

  const { deleteUploadedFile } = useUploadFile(null);

  const selectedRows = useSelector((state: any) => {
    return state.architectUsersReducer.modal.queryParams;
  });

  const selectedFilesCount = useMemo(() => {
    if (!selectedRows) {
      return 0;
    }
    return selectedRows?.reduce?.((acc: number, row: any) => {
      return acc + row.original.documents.length;
    }, 0);
  }, [selectedRows]);
  useEffect(() => {
    if (tableType) {
      SetUploadTableType(tableType);
      localStorage.setItem("uploadTableType", tableType || "");
    }
  }, [tableType, SetUploadTableType]);
  const handleDeleteClick = async () => {
    if (!savedBatches || !tableType) return;
    return await Promise.all(
      selectedRows?.map(async (row: any) => {
        const IDsToBeDeleted: string[] = [];
        const savedBatch: SavedBatch = row.original;
        savedBatch.documents.forEach((document: Document) => {
          IDsToBeDeleted.push(document.id);
        });
        return deleteUploadedFile(savedBatch.id, tableType, IDsToBeDeleted);
      })
    );
    // setTimeout(() => {
    //
    // }, 500);
  };

  const deleteSelectedHandler = useCallback(async () => {
    const deletedRows = await handleDeleteClick();
    const deletedItemsCount = deletedRows?.reduce((acc: number, row: any) => {
      return acc + row?.length;
    }, 0);
    await getSavedBatches(generatedSavedUploadsFilters, {
      documentType: tableType,
      paginationParams: {
        take: savedBatchesPageSize,
        skip: 0
      }
    }).then(data => {
      setSavedBatches(data);
    });
    SetOpenModalAction({
      isOpen: true,
      type: "DeleteSuccessPopup",
      queryParams: deletedItemsCount
    });
  }, [
    SetOpenModalAction,
    handleDeleteClick,
    generatedSavedUploadsFilters,
    tableType
  ]);

  const refetchSavedBatches = useCallback(
    async (paginationParams: PaginationParams, sortingParams?: any) => {
      setLoading(true);
      try {
        const newBatches = await getSavedBatches(generatedSavedUploadsFilters, {
          documentType: tableType,
          paginationParams,
          sortingParams
        });
        setSavedBatches(newBatches);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleTableSort = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState.length ? sortingState : null;
      refetchSavedBatches({ skip: 0 }, sortingParams);
    },
    [refetchSavedBatches]
  );

  const handleTableSizeChange = useCallback(
    (el: any) => {
      setSavedBatchesPageSize(+el.value);
      refetchSavedBatches(generatedSavedUploadsFilters, {
        documentType: tableType,
        paginationParams: {
          take: +el.value,
          skip: 0
        }
      });
    },
    [setSavedBatchesPageSize, refetchSavedBatches, tableType]
  );

  useEffect(() => {
    if (modal.isOpen && modal.type.includes("SavedUploadsFilterModal")) {
      UploaderListAction("SAVED");
    }
  }, [modal.isOpen]);
  const handleBatchClick = useCallback(
    (row: Row<TData>) => {
      if (!row?.original?.documents?.length) return;
      const savedBatchId = row.original.id;
      history.push(
        `/user-management/upload/uploader/${tableType}/${savedBatchId}`
      );
    },
    [tableType]
  );

  const columnHelper = createColumnHelper<TData>();

  const COLUMNS = useMemo(() => {
    const ROW_SELECTOR_COLUMN = columnHelper.display({
      id: "actionColumnLeft",
      size: 70,
      header: ({ table }) => (
        <SelectCheckbox
          onChange={table.getToggleAllRowsSelectedHandler()}
          CustomInput={() => {
            return (
              <div className={styles.customInput}>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </div>
            );
          }}
          selectedFlatRows={table.getSelectedRowModel().flatRows}
          control={control}
          name={"uploadType"}
          placeholder={""}
          options={[
            ...rowSelectionOptions.default,
            ...rowSelectionOptions.delete
          ]}
        />
      ),
      cell: ({ row }) => (
        <div>
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            onClick={e => e.stopPropagation()}
          />
        </div>
      )
    });

    const ARROW_COLUMN = columnHelper.display({
      id: "actionColumnRight",
      size: 20,
      cell: () => (
        <div className={styles.arrowContainer}>
          <Icon kind="arrow_right" />
        </div>
      )
    });

    const commonColumns = {
      selector: ROW_SELECTOR_COLUMN,
      numberOfDocuments: columnHelper.accessor("numberOfDocuments", {
        header: "Number of Documents",
        enableSorting: false,
        cell: ({ cell }) => (
          <div className={styles.numberOfFiles_container}>
            <Icon kind="file" />
            <span>{cell.getValue()}</span>
          </div>
        )
      }),
      userTitle: columnHelper.accessor("userTitle", {
        header: "Uploader",
        enableSorting: true
      }),
      savedAt: columnHelper.accessor("savedAt", {
        header: "Progress Saved",
        enableSorting: true,
        cell: ({ cell }) => {
          return cell.getValue()
            ? DateTime.fromJSDate(new Date(cell.getValue() as string))
                .toUTC()
                .toFormat("dd MMMM yyyy")
            : "-";
        }
      }),
      arrow: ARROW_COLUMN
    };
    const COLUMNS_TAX_DOC = Object.values(commonColumns) as ColumnDef<
      TData,
      unknown
    >[];
    const COLUMNS_DEFAULT = [
      commonColumns.selector,
      commonColumns.numberOfDocuments,
      columnHelper.accessor("startDate", {
        header: "Document Date",
        enableSorting: true
      }),
      commonColumns.userTitle,
      commonColumns.savedAt,
      commonColumns.arrow
    ] as ColumnDef<TData, unknown>[];
    if (tableType === "tax-doc") {
      return COLUMNS_TAX_DOC;
    } else {
      return COLUMNS_DEFAULT;
    }
  }, [tableType]);

  useEffect(() => {
    if (tableType !== null) {
      setLoading(true);
      getSavedBatches(generatedSavedUploadsFilters, {
        documentType: tableType,
        paginationParams: {
          take: savedBatchesPageSize,
          skip: 0
        }
      }).then((data: any) => {
        setTimeout(() => {
          setLoading(false);
        }, 800);
        setSavedBatches(data);
      });
    }
  }, [generatedSavedUploadsFilters, tableType]);
  const filterIconClicked = useCallback(() => {
    SetOpenModalAction({
      isOpen: true,
      type: ["SavedUploadsFilterModal"]
    });
  }, [SetOpenModalAction]);

  const handleClick = (type: string, itemValue?: string) => {
    if (type === "uploadedAt") {
      SetSavedUploadsDateRange(null);
    } else
      SetSavedUploadsFilters(
        savedUploadsFilters.filter((el: IChip) => el.itemValue !== itemValue)
      );
  };
  const searchEmpty = () => {
    GenerateSavedUploadsFilter([]);
    SetSavedUploadsFilters([]);
    SetSavedUploadsDateRange(null);
  };
  return (
    <div className={styles.container}>
      <div className={cn(styles.filter)}>
        <SelectTableType
          setTableType={setTableType}
          defaultValue={
            tableType
              ? {
                  label: options.uploadType.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === tableType
                  )?.label,
                  value: tableType
                }
              : null
          }
        />
        {tableType ? <FilterBar onClick={filterIconClicked} /> : null}
      </div>

      {savedUploadsFilters.length || savedUploadsDateRange ? (
        <div className={cn(styles.filter_chips)}>
          {tableType ? (
            <div className={cn(styles.chips)}>
              {savedUploadsFilters.map((el: IChip) => {
                return (
                  <Chip
                    handleClick={() => handleClick(el.type, el.itemValue)}
                    type={el.type}
                    typeName={el.typeName}
                    label={el.label}
                    value={el.value}
                    itemValue={el.itemValue}
                    key={el.itemValue}
                  />
                );
              })}
              {savedUploadsDateRange !== null && (
                <Chip
                  handleClick={() => handleClick("uploadedAt")}
                  type="uploadedAt"
                  typeName="Upload Date"
                  value={`${savedUploadsDateRange?.startDate} - ${savedUploadsDateRange?.endDate}`}
                  label={`${DateTime.fromJSDate(
                    new Date(savedUploadsDateRange?.startDate)
                  )
                    .toFormat("d MMM yyyy")
                    .toUpperCase()} - ${DateTime.fromJSDate(
                    new Date(savedUploadsDateRange?.endDate)
                  )
                    .toFormat("d MMM yyyy")
                    .toUpperCase()}`}
                  itemValue="uploadedAt"
                />
              )}
              <div />
            </div>
          ) : null}
          <div
            className={cn(styles.chips_clear)}
            onClick={() => {
              searchEmpty();
            }}
          >
            Clear filter
          </div>
        </div>
      ) : null}

      <Condition
        condition={Boolean(tableType)}
        Truthy={
          <Table
            columns={COLUMNS}
            data={savedBatches?.batches || []}
            onRowClick={handleBatchClick}
            loading={loading}
            withPagination={savedBatches && savedBatches?.totalCount > 10}
            pagination={{
              fetchData: refetchSavedBatches,
              defaultPage: savedBatches?.currentPage || 0,
              onSizeChange: handleTableSizeChange,
              totalItems: savedBatches?.totalCount || 0,
              defaultSize: savedBatchesPageSize
            }}
            onSort={handleTableSort}
          />
        }
      />
      <SavedUploadsFilterModal />
      <DatePickerModal type="SAVED_UPLOADS" />
      <DeleteSelectedPopup
        onClick={deleteSelectedHandler}
        title={`Delete ${selectedFilesCount} ${
          selectedFilesCount > 1 ? "documents" : "document"
        }?`}
      />
      <DeleteSuccessPopup />
    </div>
  );
};

export default Saved;
