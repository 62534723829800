import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./History.module.scss";

import { sharedModalTypes } from "../../../../components/shared/Modals/Modals.constants";
import DatePickerModal from "../../../../components/shared/Modals/SetDatePickerModal";
import SetDownloadPopup from "../../../../components/shared/Modals/SetDownloadPopup";
import SetDownloadReportPopup from "../../../../components/shared/Modals/SetDownloadReportPopup";
import HistoryFilterModal from "../../../../components/shared/Modals/SetHistoryFilterModal";
import SetImpersonatePopup from "../../../../components/shared/Modals/SetUserControlModal/SetImpersonatePopup";
import Chip, {
  IChip
} from "../../../../components/userManagement/Filter/chip/Chip";
import FilterBar from "../../../../components/userManagement/Filter/FilterBar";
import DeleteSuccessPopup from "../../../../components/userManagement/Modals/DeleteSuccessPopup";
import Table from "../../../../components/userManagement/UploadTableV8";
import { useActions } from "../../../../hooks/useActions";
import {
  generatedHistoryFiltersSelector,
  historyDateRangeSelector,
  historyFiltersSelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import { options } from "../../constants";
import useHistory from "../../hooks/useHistory";
import { PaginationParams, SizeState, TableType } from "../../types";
import SelectTableType from "../SelectTableType";
import { TData } from "../Upload/Upload.type";
import { FilterType } from "./History.type";
import { getHistoryColumns } from "./History.utils";

const History = () => {
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;
  const historyDateRange = useSelector(historyDateRangeSelector);
  const historyFilters = useSelector(historyFiltersSelector);
  const generatedHistoryFilters = useSelector(generatedHistoryFiltersSelector);
  const modal = useSelector(modalSelector);
  const [historyData, setHistoryData] = useState<Record<any, any>>({});
  const [historyPageSize, setHistoryPageSize] = useState(10);
  const tableData = useMemo(() => historyData?.result || [], [historyData]);
  const { getHistory } = useHistory();
  const {
    SetOpenModalAction,
    UploaderListAction,
    SetHistoryFilters,
    SetHistoryDateRange,
    GenerateHistoryFilter,
    SetUploadTableType
  } = useActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>();
  const [fetchedSearchValue, setFetchedSearchValue] = useState<string | null>();
  const [rowData, setRowData] = useState();
  const [tableType, setTableType] = useState<TableType>(
    initialTableType || "statement-doc"
  );

  const { control } = useForm();

  const [filterType, setFilterType] = useState<FilterType>("HISTORY");

  const columns = useMemo(() => {
    return getHistoryColumns(tableType, control, fetchedSearchValue);
  }, [tableType, tableData, fetchedSearchValue]) as ColumnDef<TData, unknown>[];

  // Reflects table type change in the localStorage and in Redux store.
  useEffect(() => {
    if (tableType) {
      SetUploadTableType(tableType);
      localStorage.setItem("uploadTableType", tableType);
    }
  }, [tableType, SetUploadTableType]);

  // When download report modal is opened, set filter type to "DOWNLOAD"
  // "filterType: is used in the DatePickerModal component to determine -
  // appropreate localStorage and redux key to store the date range in.
  useEffect(() => {
    if (
      modal.isOpen &&
      modal.type.includes(sharedModalTypes.DOWNLOAD_REPORT_MODAL)
    ) {
      setFilterType("DOWNLOAD");
    }
  }, [modal]);

  // Fetch saved batches on filter change or table type change
  useEffect(() => {
    setLoading(true);
    refetchTableData(generatedHistoryFilters, {
      documentType: tableType
    });
  }, [generatedHistoryFilters, tableType]);

  // Fetches the list of uploaders (Saga GetUploaderListAsync) when filter modal openes.
  useEffect(() => {
    if (
      modal.isOpen &&
      modal.type.includes(sharedModalTypes.HISTORY_FILTER_MODAL)
    ) {
      UploaderListAction("PUBLISHED");
    }
  }, [modal.isOpen]);

  const refetchTableData = useCallback(
    (paginationParams: PaginationParams, sortingParams?: any) => {
      setLoading(true);
      getHistory(generatedHistoryFilters, {
        documentType: tableType,
        paginationParams,
        sortingParams
      })
        .then(data => {
          const sortedData = { ...data };
          if (fetchedSearchValue) {
            sortedData.result = data.result.map((batch: any) => ({
              ...batch,
              documents: batch.documents.sort((a: any, b: any) => {
                const aIncludesSearchValue = a.fileName
                  .toLowerCase()
                  .includes(fetchedSearchValue?.toLowerCase());
                const bIncludesSearchValue = b.fileName
                  .toLowerCase()
                  .includes(fetchedSearchValue?.toLowerCase());

                if (aIncludesSearchValue && !bIncludesSearchValue) {
                  return -1;
                } else if (!aIncludesSearchValue && bIncludesSearchValue) {
                  return 1;
                } else {
                  return 0;
                }
              })
            }));
          }

          setHistoryData(sortedData);
        })
        .finally(() => setLoading(false));
    },
    [
      getHistory,
      setLoading,
      setHistoryData,
      fetchedSearchValue,
      generatedHistoryFilters,
      tableType
    ]
  );

  const handleRowClick = useCallback(
    el => {
      if (!el?.original?.documents) return;

      setRowData(el?.original);
      SetOpenModalAction({
        isOpen: true,
        type: "downloadModalClick"
      });
    },
    [SetOpenModalAction, setRowData, rowData]
  );

  const handleTableSizeChange = useCallback(
    (size: SizeState) => {
      setHistoryPageSize(+size.value);
      refetchTableData({ skip: 0, take: +size.value });
    },
    [setHistoryData, refetchTableData]
  );
  const handleTableSort = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState.length ? sortingState : null;
      refetchTableData({ skip: 0 }, sortingParams);
    },
    [refetchTableData]
  );

  const showPagination =
    historyData?.totalCount > 10
      ? historyData?.totalCount ?? "Default Value"
      : null;
  const filterIconClicked = useCallback(() => {
    setFilterType("HISTORY");
    SetOpenModalAction({
      isOpen: true,
      type: [sharedModalTypes.HISTORY_FILTER_MODAL]
    });
  }, [SetOpenModalAction]);

  const handleClick = (type: string, itemValue?: string) => {
    if (type === "uploadedAt") {
      SetHistoryDateRange(null);
    } else
      SetHistoryFilters(
        historyFilters.filter((el: IChip) => el.itemValue !== itemValue)
      );
  };

  const searchEmpty = () => {
    GenerateHistoryFilter([]);
    SetHistoryFilters([]);
    SetHistoryDateRange(null);
  };

  const searchClick = useCallback(() => {
    const searchValueBody = () => {
      if (searchValue) {
        setFetchedSearchValue(searchValue?.trim());
        return [
          {
            operation: "like",
            name: "payeeCode",
            value: searchValue.trim()
          }
        ];
      }
    };
    const body = searchValue
      ? generatedHistoryFilters
          .filter((el: any) => el.name !== "payeeCode")
          .concat(searchValueBody())
      : generatedHistoryFilters.filter((el: any) => el.name !== "payeeCode");
    GenerateHistoryFilter(body);
  }, [generatedHistoryFilters, searchValue, setFetchedSearchValue]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.filter)}>
        <SelectTableType
          setTableType={setTableType}
          defaultValue={
            tableType
              ? {
                  label: options.uploadType.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === tableType
                  )?.label,
                  value: tableType
                }
              : null
          }
        />
        {tableType ? (
          <FilterBar
            hasSearchInput
            searchClick={searchClick}
            onChangeSearch={(val: string) => {
              setSearchValue(val);
              setFetchedSearchValue(null);
            }}
            searchValue={searchValue}
            searchEmpty={searchEmpty}
            onClick={filterIconClicked}
            placeholder="Search  by payee code"
          />
        ) : null}
      </div>
      {historyFilters.length || historyDateRange ? (
        <div className={cn(styles.filter_chips)}>
          {tableType ? (
            <div className={cn(styles.chips)}>
              {historyFilters.map((el: IChip) => {
                return (
                  <Chip
                    handleClick={() => handleClick(el.type, el.itemValue)}
                    type={el.type}
                    typeName={el.typeName}
                    label={el.label}
                    value={el.value}
                    itemValue={el.itemValue}
                    key={el.itemValue}
                  />
                );
              })}
              {historyDateRange && (
                <Chip
                  handleClick={() => handleClick("uploadedAt")}
                  type="uploadedAt"
                  typeName="Upload Date"
                  value={`${historyDateRange?.startDate} - ${historyDateRange?.endDate}`}
                  label={`${DateTime.fromJSDate(
                    new Date(historyDateRange?.startDate)
                  )
                    .toFormat("d MMM yyyy")
                    .toUpperCase()} - ${DateTime.fromJSDate(
                    new Date(historyDateRange?.endDate)
                  )
                    .toFormat("d MMM yyyy")
                    .toUpperCase()}`}
                  itemValue="uploadedAt"
                />
              )}
              <div />
            </div>
          ) : null}
          <div
            className={cn(styles.chips_clear)}
            onClick={() => {
              searchEmpty();
            }}
          >
            Clear filter
          </div>
        </div>
      ) : null}
      {tableType ? (
        <Table
          columns={columns}
          data={tableData}
          loading={loading}
          onSort={handleTableSort}
          onRowClick={handleRowClick}
          withPagination={showPagination}
          pagination={{
            totalItems: historyData?.totalCount,
            defaultSize: historyPageSize,
            fetchData: refetchTableData,
            onSizeChange: handleTableSizeChange,
            defaultPage: historyData?.currentPage
          }}
        />
      ) : null}
      <HistoryFilterModal />
      <DeleteSuccessPopup />
      <SetDownloadReportPopup />
      <DatePickerModal type={filterType} />
      <SetDownloadPopup rowData={rowData} />
      <SetImpersonatePopup />
    </div>
  );
};

export default History;
