import { createSelector } from "reselect";

const initialSelector = (state: any) => state.architectUsersReducer;

const initialWorksSelector = createSelector(initialSelector, state => state);
const modalSelector = createSelector(initialSelector, state => state.modal);
const UMSTypeSearchSelector = createSelector(
  initialSelector,
  state => state.UMSTypeSearch
);
const bodySelector = createSelector(initialSelector, state => state.body);

//HISTORY FILTER SELECTORS
const historyFiltersSelector = createSelector(
  initialSelector,
  state => state.historyFilters
);
const historyFilterModalSelector = createSelector(
  initialSelector,
  state => state.historyFilterModal
);
const historyDateRangeSelector = createSelector(
  initialSelector,
  state => state.historyDateRange
);
const historyDateRangeModalSelector = createSelector(
  initialSelector,
  state => state.historyDateRangeModal
);
const generatedHistoryFiltersSelector = createSelector(
  initialSelector,
  state => state.generatedHistoryFilters
);
//HISTORY FILTER SELECTORS

// DOWNLOAD FILTER SELECTORS
const downloadFiltersSelector = createSelector(
  initialSelector,
  state => state.downloadFilters
);
const downloadFilterModalSelector = createSelector(
  initialSelector,
  state => state.downloadFilterModal
);
const downloadDateRangeSelector = createSelector(
  initialSelector,
  state => state.downloadDateRange
);
const downloadDateRangeModalSelector = createSelector(
  initialSelector,
  state => state.downloadDateRangeModal
);

//DOWNLOAD FILTER SELECTORS

//SAVED UPLOADS SELECTORS

const savedUploadsFiltersSelector = createSelector(
  initialSelector,
  state => state.savedUploadsFilters
);
const savedUploadsFilterModalSelector = createSelector(
  initialSelector,
  state => state.savedUploadsFilterModal
);
const savedUploadsDateRangeSelector = createSelector(
  initialSelector,
  state => state.savedUploadsDateRange
);
const savedUploadsDateRangeModalSelector = createSelector(
  initialSelector,
  state => state.savedUploadsDateRangeModal
);
const generatedSavedUploadsFiltersSelector = createSelector(
  initialSelector,
  state => state.generatedSavedUploadsFilters
);

const uploadTableTypeSelector = createSelector(
  initialSelector,
  state => state.uploadTableType
);
//SAVED UPLOADS SELECTORS

const filtersSelector = createSelector(initialSelector, state => state.filters);
const modalFiltersSelector = createSelector(
  initialSelector,
  state => state.modalFilter
);
const generatedFiltersSelector = createSelector(
  initialSelector,
  state => state.generatedFilters
);
const dateRangeSelector = createSelector(
  initialSelector,
  state => state.dateRange
);
const modifiersSelector = createSelector(
  initialSelector,
  state => state.modifiers
);

export {
  bodySelector,
  dateRangeSelector,
  downloadDateRangeModalSelector,
  downloadDateRangeSelector,
  downloadFilterModalSelector,
  downloadFiltersSelector,
  filtersSelector,
  generatedFiltersSelector,
  generatedHistoryFiltersSelector,
  generatedSavedUploadsFiltersSelector,
  historyDateRangeModalSelector,
  historyDateRangeSelector,
  historyFilterModalSelector,
  historyFiltersSelector,
  initialWorksSelector,
  modalFiltersSelector,
  modalSelector,
  modifiersSelector,
  savedUploadsDateRangeModalSelector,
  savedUploadsDateRangeSelector,
  savedUploadsFilterModalSelector,
  savedUploadsFiltersSelector,
  UMSTypeSearchSelector,
  uploadTableTypeSelector
};
