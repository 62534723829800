import { FC, PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { useOktaAuth } from "@okta/okta-react";
import cn from "classnames";

import styles from "./Layout.module.scss";

import { useActions } from "../../hooks/useActions";
import { profileSelector } from "../../store/appStatic/selectors";
import Footer from "./Footer";
import Header from "./Header";
import ManagementHeader from "./UserManagementLayout/ManagementHeader";

const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const profile = useSelector(profileSelector);
  const { setProfileAction } = useActions();

  const isMobile = pathname.includes("mobile");
  const isUserManagement = pathname.includes("user-management");

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (profile) {
        localStorage.setItem("profileEmail", profile.email);
      } else {
        setProfileAction(navigate);
      }
    }
  }, [authState?.isAuthenticated, navigate, profile, setProfileAction]);

  if (isMobile) {
    return (
      <div style={{ background: "black" }}>
        <div>{children}</div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {!isUserManagement && <Header />}
      <div
        className={cn(
          isUserManagement
            ? styles.userManagementContainer
            : styles.clientContainer
        )}
      >
        <div
          className={cn(
            {
              [styles.userManagementWrapper]: isUserManagement
            },
            styles.wrapper
          )}
        >
          {isUserManagement && <ManagementHeader />}
          <div
            className={cn({ [styles.userManagementContent]: isUserManagement })}
          >
            {children}
          </div>
        </div>
        <Footer hide={isUserManagement} />
      </div>
    </div>
  );
};

export default Layout;
