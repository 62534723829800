import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./Advances.module.scss";
import { ReactComponent as CalendarIcon } from "../../icons/advance_calendar.svg";

import Loader from "../../components/shared/Loading";
import RequestAdvanceModal from "../../components/shared/Modals/RequestAdvanceModal/RequestAdvanceModal";
import Button, { ButtonType } from "../../components/shared/UiButton";
import { UserRole } from "../../components/userManagement/api/users";
import { useActions } from "../../hooks/useActions";
import { advancedSelector } from "../../store/advance/selectors";
import {
  clientsSelector,
  payeeCodeSelector,
  profileSelector
} from "../../store/appStatic/selectors";

const Advances = () => {
  const { GetAdvanceRequestAction, GetLedgersAction } = useActions();
  const history = useHistory();
  const payeeCode = useSelector(payeeCodeSelector);
  const profile = useSelector(profileSelector);
  const clients = useSelector(clientsSelector);
  const { t } = useTranslation("translation");
  const { SetOpenModalAction } = useActions();
  const advanceHistory = useSelector(advancedSelector);
  const isUserImpersonated = Boolean(localStorage.getItem("impersonateId"));
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const userId = isUserImpersonated
    ? localStorage.getItem("impersonateId")
    : profile?.id;
  useEffect(() => {
    if (advanceHistory.data === null) {
      payeeCode && profile && GetAdvanceRequestAction(userId, payeeCode);
    }
  }, [advanceHistory.data, payeeCode, profile]);
  const firstHistoryElement = advanceHistory?.data?.history?.[0];
  useEffect(() => {
    GetLedgersAction(payeeCode, history);
  }, [payeeCode, clients]);
  return advanceHistory?.loading || advanceHistory?.loading === undefined ? (
    <Loader />
  ) : (
    <div className="container">
      <div>
        {advanceHistory?.data?.requestAllowed ? (
          <div className={cn(styles.advanceContainer)}>
            <h1 className={cn(styles.pageTitle)}>{t(`advancesPage.title`)}</h1>
            <div>
              <p className={cn(styles.description)}>
                {t(`advancesPage.description`)}
              </p>
              <Button
                disabled={
                  profile?.role === UserRole.ADMIN ||
                  profile?.role === UserRole.SUPER_ADMIN
                }
                variant={ButtonType.Primary}
                onClick={() => {
                  SetOpenModalAction({ isOpen: true, type: "requestModal" });
                }}
              >
                {t(`buttons.requestAdvance`)}
              </Button>
            </div>
          </div>
        ) : (
          <div className={cn(styles.history)}>
            <div className={cn(styles.historyBlock_title)}>
              <h1 className={cn(styles.pageTitle)}>
                {t(`advancesPage.advanceRequested`)}
              </h1>
              <div
                className={cn(
                  styles.hideOnDesktop,
                  styles.historyBlock_title_date
                )}
              >
                <CalendarIcon />
                <p className={cn(styles.date)}>
                  {DateTime.fromJSDate(
                    new Date(firstHistoryElement?.requestDate)
                  ).toLocaleString(DateTime.DATE_FULL)}
                </p>
              </div>
            </div>
            <div className={cn(styles.firstElement)}>
              <div className={cn(styles.historyBlock)}>
                <p>{t(`advancesPage.requestedAmount`)}</p>
                <p className={cn(styles.amount)}>
                  {new Intl.NumberFormat(navigator.language, {
                    currency: ledgers?.currency,
                    style: "currency",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(firstHistoryElement?.amount)}
                </p>
                <p className={cn(styles.hideOnMobile)}>
                  {t(`advancesPage.requestSubmitted`)}
                </p>
                <p className={cn(styles.date, styles.hideOnMobile)}>
                  {DateTime.fromJSDate(
                    new Date(firstHistoryElement?.requestDate)
                  ).toLocaleString(DateTime.DATE_FULL)}
                </p>
              </div>
              <div className={cn(styles.firstElement_msg)}>
                <p>{t(`advancesPage.successRequestTx`)}</p>
                <p>{t(`advancesPage.successRequestTx2`)}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {profile && <RequestAdvanceModal />}
    </div>
  );
};
export default Advances;
