import { AuthnTransaction } from "@okta/okta-auth-js";

import {
  AuthFactor,
  AuthFactorProvider,
  AuthFactorType,
  AuthPolicy
} from "./MfaForm.types";

/**
 * Gets auto push settings
 * @param transaction Auth transaction
 * @returns Auto push settings
 */
export const getIsAutoPushEnabled = (
  transaction: AuthnTransaction
): boolean => {
  const factors = (transaction.factors as AuthFactor[]) ?? [];
  const pushFactor = factors.find(
    f =>
      f.factorType === AuthFactorType.PUSH &&
      f.provider === AuthFactorProvider.OKTA
  );
  const policy = transaction.policy as AuthPolicy | undefined;
  const isAutoPushEnabled = pushFactor?.id
    ? policy?.factorsPolicyInfo[pushFactor.id]?.autoPushEnabled
    : false;
  return isAutoPushEnabled ?? false;
};
