import React from "react";
import { Control, FieldValues } from "react-hook-form";
import { Tooltip } from "@material-ui/core";
import { createColumnHelper } from "@tanstack/react-table";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./History.module.scss";

import Icon from "../../../../components/shared/Icon";
import SelectCheckbox from "../../../../components/userManagement/Select&chekbox/SelectChekbox";
import { rowSelectionOptions } from "../../../../components/userManagement/UploadTableV8/UploadTable.constants";
import { TableType } from "../../types";
import { downloadCSV } from "../../utils";
import FileNameColumnCell from "./components/FileNameColumnCell";
import { TData } from "./History.type";

const columnHelper = createColumnHelper<TData>();

export const getHistoryColumns = (
  tableType: TableType,
  control: Control<FieldValues, any>,
  fetchedSearchValue?: string | null
) => {
  tableType;
  fetchedSearchValue;

  const commonColumns = {
    selectionColumn: columnHelper.display({
      id: "actionColumnLeft",
      size: 80,
      header: ({ table }) => (
        <SelectCheckbox
          onChange={table.getToggleAllRowsSelectedHandler()}
          CustomInput={() => {
            return (
              <div className={styles.customInput}>
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </div>
            );
          }}
          selectedFlatRows={table.getSelectedRowModel().flatRows}
          control={control}
          name={"uploadType"}
          placeholder={""}
          options={[
            ...rowSelectionOptions.default,
            ...rowSelectionOptions.download
          ]}
        />
      ),
      cell: ({ row }) => (
        <div>
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </div>
      )
    }),
    userTitle: columnHelper.accessor("userTitle", {
      header: "Uploader",
      cell: ({ cell }) => <p>{cell.getValue()}</p>
    }),
    publishedAt: columnHelper.accessor("publishedAt", {
      header: "Date Uploaded",
      cell: ({ cell }) =>
        DateTime.fromJSDate(new Date(cell.getValue()))
          .toUTC()
          .toFormat("dd MMMM yyyy")
    }),
    name: columnHelper.accessor("documents.fileName", {
      header: "Files",
      size: 400,
      enableSorting: false,
      cell: ({ row }) => (
        <FileNameColumnCell
          row={row}
          tableType={tableType}
          fetchedSearchValue={fetchedSearchValue}
        />
      )
    }),
    download: columnHelper.display({
      id: "actionColumnRight",
      size: 50,
      header: "",
      enableSorting: false,
      cell: ({ row }) => (
        <Tooltip title="Download Report">
          <div
            onClick={event => {
              event.stopPropagation();
              downloadCSV.handleClickDownload(row.original.id, tableType);
            }}
          >
            <Icon kind="file_dnld_icon" className={cn(styles.files_download)} />
          </div>
        </Tooltip>
      )
    })
  };

  if (tableType === "tax-doc") {
    return Object.values(commonColumns);
  } else {
    return [
      commonColumns.selectionColumn,
      commonColumns.userTitle,
      commonColumns.publishedAt,
      columnHelper.accessor("startDate", {
        header: "Document Date",
        cell: ({ row }) => {
          return `
      ${DateTime.fromJSDate(new Date(row.original.startDate)).toFormat(
        "MMMM"
      )} - ${DateTime.fromJSDate(new Date(row.original.endDate)).toFormat(
            "MMMM yyyy"
          )}`;
        }
      }),
      commonColumns.name,
      commonColumns.download
    ];
  }
};
