import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import styles from "./UserManagement.module.scss";

import SetDwnldCSVModal from "../../components/shared/Modals/SetDwnldCSVModal";
import SetEditUserModal from "../../components/shared/Modals/SetEditUserModal";
import FilterModal from "../../components/shared/Modals/SetFilterModal";
import SetUserControlModal from "../../components/shared/Modals/SetUserControlModal";
import { clearDup } from "../../configs/utils";
import { useActions } from "../../hooks/useActions";
import { profileSelector } from "../../store/appStatic/selectors";
import { UserRole } from "../../types/roles";
import SuperAdminLayout from "./components/SuperAdminLayout";

const UserManagement = () => {
  const { clearClientState, SetSearchedValue } = useActions();
  const history = useHistory();
  const profile = useSelector(profileSelector);
  useEffect(() => {
    localStorage.setItem("impersonateId", "");
    localStorage.setItem("impersonateEmail", "");
    localStorage.setItem("impersonateUser", "");
    localStorage.setItem("impersonateLn", "");
    localStorage.setItem("selectedDate", "");
    localStorage.setItem("payeeCode", "");
    if (window) {
      window.ledgerId = null;
    }
    clearClientState();
  }, []);
  const isSuperAdmin = profile?.role === UserRole.SUPER_ADMIN;
  useEffect(() => {
    SetSearchedValue("");
    localStorage.setItem("UMSBackUrl", history.location.pathname);
    clearDup();
  }, [history]);
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.content} Architect_content`}>
        <Switch>
          {!isSuperAdmin && (
            <Redirect
              exact
              from={"/user-management/activity"}
              to={"/user-management/clients"}
            />
          )}
          <Route
            path={["/user-management/:tab/:status", "/user-management/:tab"]}
            component={SuperAdminLayout}
            exact
          />
          <Redirect
            from={"/user-management"}
            to={"/user-management/activity"}
          />
        </Switch>
      </div>
      <SetUserControlModal />
      <SetEditUserModal />
      <SetDwnldCSVModal />
      <FilterModal />
    </div>
  );
};

export default UserManagement;
