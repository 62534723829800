/**
 * Deletes cookie by name
 * @param name Cookie name
 */
export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * Gets cookie value by name
 * @param name Cookie name
 * @returns Cookie value if exists
 */
export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2
    ? parts
        .pop()
        ?.split(";")
        .shift()
    : undefined;
};

/**
 * Sets cookie
 * @param name Cookie name
 * @param value Cookie value
 * @param minutes Minutes to expire
 */
export const setCookie = (
  name: string,
  value: string,
  minutes: number
): void => {
  if (minutes === 0) {
    deleteCookie(name);
    return;
  }
  const date = new Date(Date.now() + minutes * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};
