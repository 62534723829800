import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import classNames from "classnames";

import styles from "./AcceptTermsOfUse.module.scss";

import Loader from "../../components/shared/Loading";
import Button, { ButtonType } from "../../components/shared/UiButton";
import CheckBoxFiled from "../../components/shared/UiCheckBox";
import { useActions } from "../../hooks/useActions";
import { axiosInstance } from "../../services/axios";
import {
  errorsSelector,
  profileSelector
} from "../../store/appStatic/selectors";
import { UserRole } from "../../types/roles";
import { UserProfile, UserSettings } from "../../types/userProfile";
import { requiredValidaton } from "../../utils/validation";
import TermsOfUse from "../TermsOfUse";
import { AcceptTermsOfUseItem } from "./AcceptTermsOfUse.types";

/**
 * Renders the page where the user accepts the terms of use
 * @returns JSX element
 */
const AcceptTermsOfUse = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { control, formState, handleSubmit } = useForm<AcceptTermsOfUseItem>({
    defaultValues: { isTermAccepted: false },
    mode: "onChange"
  });

  const errors = useSelector<unknown, unknown[] | null>(errorsSelector);
  const profile = useSelector<unknown, UserProfile | null>(profileSelector);

  const scrolledRef = useRef<any>();

  const [isAcceptDisabled, setIsAcceptDisabled] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const [shadowPosition, setShadowPosition] = useState<"down" | "up">("down");

  const { changeIsTermAcceptedAction } = useActions();

  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const handleNavigation = (): void => {
    const currentScrollTop = scrolledRef?.current?.scrollTop;
    if (
      currentScrollTop >
      scrolledRef?.current?.scrollHeight -
        scrolledRef?.current?.clientHeight -
        10
    ) {
      setIsAcceptDisabled(false);
    }
    setScrollTop(currentScrollTop);
    setShadowPosition(currentScrollTop > scrollTop ? "up" : "down");
  };

  const onSubmit = async (): Promise<void> => {
    try {
      const response = await axiosInstance.put<UserSettings>(
        `/api/users/${profile?.id}/settings`,
        { isTermAccepted: true }
      );
      changeIsTermAcceptedAction(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if ((errors ?? []).filter(el => el).length > 0) {
      navigate("/access-denied");
      return;
    }
    if (profile?.role && profile.settings?.isTermAccepted) {
      const source = new URL(window.location.href).searchParams.get("source");
      if (source && source !== "/") {
        navigate(source);
        return;
      }
      if ([UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(profile.role)) {
        navigate("/user-management/activity");
        return;
      }
      if (UserRole.EXTERNAL_USER === profile.role) {
        navigate("/accounts");
        return;
      }
      navigate("/access-denied");
    }
  }, [errors, navigate, profile]);

  if (!profile || profile.settings?.isTermAccepted) {
    return <Loader />;
  }

  return (
    <div className="container">
      <form
        className={classNames(styles.form, styles.terms)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TermsOfUse
          handleNavigation={handleNavigation}
          scrolledRef={scrolledRef}
          shadowPosition={shadowPosition}
          showDate={false}
        />
        <div className={styles.toolbar}>
          <Controller
            control={control}
            name="isTermAccepted"
            render={({ field }) => (
              <CheckBoxFiled
                {...field}
                checked={field.value}
                className={classNames(
                  styles.checkbox,
                  isAcceptDisabled && styles.disabledCheckbox
                )}
                disabled={isAcceptDisabled}
                label="I have read and agree to the Terms of Use."
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <div className={styles.buttons}>
            <Button
              onClick={() => navigate("/logout")}
              type="button"
              variant={ButtonType.Text}
            >
              Cancel
            </Button>
            <Button
              disabled={formState.isSubmitting || !formState.isValid}
              loading={formState.isSubmitting}
              type="submit"
              variant={ButtonType.Primary}
            >
              Agree
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AcceptTermsOfUse;
