import React from "react";
import { useHistory } from "react-router";
import cn from "classnames";

import styles from "./QuickFilterButton.module.scss";

type QuickFilterButtonType = {
  className?: string;
  title: string;
  count: number;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  href?: string;
  disabled?: boolean;
};

const QuickFilterButton: React.FC<QuickFilterButtonType> = ({
  className,
  title,
  count,
  active,
  href,
  disabled
}) => {
  const history = useHistory();
  const onClick = (e: any) => {
    if (disabled) return;
    if (history.location.pathname.includes(e.toLowerCase())) {
      history.push(`/user-management/${href}`);
    } else {
      history.push(`/user-management/${href}/${e.toLowerCase()}`);
    }
  };
  return (
    <div
      className={cn(styles.quickFilterButton, className, {
        [styles.active]: active,
        [styles.disabled]: disabled
      })}
      onClick={() => onClick(title)}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.count}>{count ? count : 0}</span>
    </div>
  );
};

export default QuickFilterButton;
