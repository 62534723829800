import React from "react";

import styles from "./Button.module.scss";

import { jc } from "../../../lib/styles";

export enum ButtonType {
  Approve,
  Danger,
  Default,
  Gray,
  Primary,
  Text
}

export interface ButtonProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => void;
  text?: string;
  type?: ButtonType;
}

const getButtonClassName = (props: ButtonProps): string => {
  return jc(
    `${styles.button} focus:outline-none font-suisse-intl inline-flex items-center px-4 text-13`,
    (typeof props.type === "undefined" || props.type === ButtonType.Default) &&
      `${styles.default} bg-wcm-dark border border-wcm-dark text-white`,
    props.type === ButtonType.Approve &&
      `${styles.approve} bg-wcm-green focus:bg-wcm-green-darker hover:bg-wcm-green-darker border` +
        ` border-wcm-green focus:border-wcm-green-darker hover:border-wcm-green-darker text-white`,
    props.type === ButtonType.Danger &&
      `${styles.danger} bg-wcm-danger focus:bg-wcm-danger-darker hover:bg-wcm-danger-darker border` +
        ` border-wcm-danger focus:border-wcm-danger-darker hover:border-wcm-danger-darker text-white`,
    props.type === ButtonType.Gray &&
      `${styles.gray} bg-wcm-gray focus:bg-gray-dark hover:bg-gray-dark border` +
        ` border-wcm-gray focus:border-gray-dark hover:border-gray-dark text-white`,
    props.type === ButtonType.Primary &&
      `${styles.primary} bg-wcm-accent focus:bg-wcm-accent-darker hover:bg-wcm-accent-darker border` +
        ` border-wcm-accent focus:border-wcm-accent-darker hover:border-wcm-accent-darker text-white`,
    props.type === ButtonType.Text &&
      `${styles.text} px-3 text-gray-dark focus:text-wcm-accent hover:text-wcm-accent`,
    props.className
  );
};

const Button = (props: ButtonProps): JSX.Element => {
  const children =
    typeof props.children === "undefined" ? props.text : props.children;

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    props.onClick?.(e);
  };

  return (
    <button
      className={getButtonClassName(props)}
      disabled={props.disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
