import { call, put, select, takeLatest } from "redux-saga/effects";

import { UMSTypeSearchEnum } from "../../page/UserManagement/types";
import { request } from "../../services";
import {
  GetArchitectUsersSuccessAction,
  GetMetaDataSuccessAction,
  GetModifiersSuccessAction,
  GetUploaderListSuccessAction,
  SetLoading,
  SetMetaDataLoading,
  SetModifiersLoading,
  SetUploaderListLoading
} from "./actions";
import { UMSTypeSearchSelector, uploadTableTypeSelector } from "./selectors";
import { ArchitectUsersTypes } from "./types";

const { REACT_APP_BASE_URI } = process.env;

export function* GetArchitectUserAsync({
  payload
}: any): Generator<any, any, any> {
  try {
    yield put(SetLoading(true));
    const UMSTypeSearch = yield select(UMSTypeSearchSelector);
    const endpoint = [
      UMSTypeSearchEnum.USER_EMAIL,
      UMSTypeSearchEnum.USER_NAME
    ].includes(UMSTypeSearch.value)
      ? `api/users/fetch`
      : `api/payee-accounts/fetch/`;
    const { data } = yield call(
      request,
      "post",
      `${REACT_APP_BASE_URI}${endpoint}`,
      payload.body
    );
    yield put(GetArchitectUsersSuccessAction(data, payload.isScroll));
  } catch {
    yield put(
      GetArchitectUsersSuccessAction(
        { total: 0, content: [] },
        payload.isScroll
      )
    );
  } finally {
    yield put(SetLoading(false));
  }
}

export function* GetMetaDataAsync(): Generator<any, any, any> {
  try {
    yield put(SetMetaDataLoading(true));
    const { data: userMetadata } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/users/metadata`
    );
    const { data: payeeMetadata } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/payee-accounts/metadata`
    );
    yield put(GetMetaDataSuccessAction({ ...userMetadata, ...payeeMetadata }));
  } finally {
    yield put(SetMetaDataLoading(false));
  }
}

export function* GetModifiersAsync(): Generator<any, any, any> {
  try {
    yield put(SetMetaDataLoading(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/users/modifiers`
    );
    yield put(GetModifiersSuccessAction(data));
  } finally {
    yield put(SetModifiersLoading(false));
  }
}

// TODO - check if &type=${documentType} is needed when getting the uploader list
export function* GetUploaderListAsync({
  payload
}: any): Generator<any, any, any> {
  try {
    const documentType = yield select(uploadTableTypeSelector);
    yield put(SetUploaderListLoading(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/cms/${documentType}/uploaded-by?status=${payload.status}&type=${documentType}`
    );
    yield put(GetUploaderListSuccessAction(data));
  } finally {
    yield put(SetUploaderListLoading(false));
  }
}

function* watchArchitectUsersRequest(): Generator {
  yield takeLatest(
    ArchitectUsersTypes.ARCHITECT_USERS_REQUEST,
    GetArchitectUserAsync
  );
}

function* watchMetaDataRequest(): Generator {
  yield takeLatest(ArchitectUsersTypes.METADATA_REQUEST, GetMetaDataAsync);
}

function* watchModifiersRequest(): Generator {
  yield takeLatest(ArchitectUsersTypes.MODIFIERS_REQUEST, GetModifiersAsync);
}

function* watchUploaderListRequest(): Generator {
  yield takeLatest(ArchitectUsersTypes.UPLOADER_REQUEST, GetUploaderListAsync);
}

export {
  watchArchitectUsersRequest,
  watchMetaDataRequest,
  watchModifiersRequest,
  watchUploaderListRequest
};
