/**
 * Cleanups local storage but keeps required keys
 */
export const cleanupLocalStorage = (): void => {
  const keysToSave = [
    // Language key
    "i18nextLng",
    /**
     * Okta keys. The user won't be able to login under another account
     * if the keys have been removed before logout in Incognito tab.
     */
    "okta-cache-storage",
    "okta-original-uri-storage",
    "okta-shared-transaction-storage",
    "okta-token-storage"
  ];
  const keysToRemove: string[] = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key && !keysToSave.includes(key)) {
      keysToRemove.push(key);
    }
  }
  keysToRemove.forEach(key => {
    window.localStorage.removeItem(key);
  });
};
