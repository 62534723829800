import { useMemo } from "react";
import PhoneInput from "react-phone-number-input";
import { v4 as uuidv4 } from "uuid";

import styles from "./PhoneNumberInput.module.scss";

import { PhoneNumberInputProps } from "./PhoneNumberInput.types";

const PhoneNumberInput = (props: PhoneNumberInputProps): JSX.Element => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <div>
      <label className={styles.label} htmlFor={id}>
        {props.label}
      </label>
      <div>
        <PhoneInput
          className={styles.phoneNumber}
          disabled={props.disabled}
          id={id}
          onBlur={props.onBlur}
          onChange={v => props.onChange?.(v ?? "")}
          value={props.value ?? ""}
        />
        {(props.isReservedErrorSpace || props.error) && (
          <div className={styles.error}>{props.error || "​"}</div>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberInput;
