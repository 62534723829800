import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./PageHeader.module.scss";
import { ReactComponent as LineIcon } from "../../../icons/line_icon.svg";

import { PageHeaderProps } from "./PageHeader.types";

/**
 * Renders page header according to selected varant
 * @param props React component properties
 * @returns JSX element
 */
const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { i18n } = useTranslation();

  const VariantComponent = props.variant;
  const className = classNames(
    {
      [styles.h1]: props.variant === "h1",
      [styles.h2]: props.variant === "h2",
      [styles.withGoldBorder]: props.hasGoldBorder,
      [styles.withSubtitle]: props.subtitle
    },
    props.className
  );
  const subtitleClassName = classNames(
    styles.subtitle,
    i18n.language === "RU" && styles.subtitleRu
  );

  return (
    <div className={className}>
      <VariantComponent className={styles.innerHeading}>
        {props.children}
      </VariantComponent>
      {props.subtitle && (
        <div className={subtitleClassName}>{props.subtitle}</div>
      )}
      {props.hasGoldBorder && <LineIcon className={styles.lineIcon} />}
    </div>
  );
};

export default PageHeader;
