import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import classNames from "classnames";

import styles from "./Home.module.scss";

import Loader from "../../components/shared/Loading/Loader";
import ResponseAlert, {
  ResponseAlertType
} from "../../components/shared/UiResponseAlert/ResponseAlert";
import { axiosInstance } from "../../services/axios";
import {
  payeeCodeSelector,
  profileSelector
} from "../../store/appStatic/selectors";
import { DocumentItem, DocumentType } from "../../types/documents";
import { Ledger } from "../../types/ledger";
import { getErrorDetails } from "../../utils/error";
import { downloadFile } from "../../utils/file";
import CurrentPeriod from "./components/CurrentPeriod";
import FinancialOverview from "./components/FinancialOverview";
import LatestStatements from "./components/LatestStatements";
import PriorPeriod from "./components/PriorPeriod";

/**
 * Renders home page for clients
 * @returns JSX element
 */
const Home = (): JSX.Element => {
  const payeeCode = useSelector<unknown, string | undefined>(payeeCodeSelector);
  const profile = useSelector(profileSelector);

  const { t } = useTranslation();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ledger, setLedger] = useState<Ledger>();
  const [statements, setStatements] = useState<DocumentItem[]>([]);

  /**
   * Handles download click event
   * @param statement Statement document
   * @param formats File formats to download
   */
  const onDownloadClick = async (
    statement: DocumentItem,
    formats: ("csv" | "pdf")[]
  ): Promise<void> => {
    const { endDate, startDate } = statement;
    const signedUrls = await Promise.all(
      formats.map(format =>
        axiosInstance.get(
          `/api/royalty/${payeeCode}/${startDate}/${endDate}/statement/${format.toUpperCase()}`
        )
      )
    );
    signedUrls.forEach(({ data }, i) => {
      downloadFile(`Statement_${startDate}_${endDate}.${formats[i]}`, data);
    });
  };

  useEffect(() => {
    if (payeeCode) {
      const source = axios.CancelToken.source();
      const config = { cancelToken: source.token };
      const fetchData = async () => {
        const [ledgerResponse, statementsResponse] = await Promise.all([
          axiosInstance
            .get<Ledger>(`/api/royalty/${payeeCode}/v2/ledger`, config)
            .catch(e => {
              // Suppress the error if ledger doesn't exist yet
              if (getErrorDetails(e).status === 404) {
                return e;
              }
              throw e;
            }),
          axiosInstance.get<DocumentItem[]>(
            `/api/cms/payee-code-documents/${payeeCode}?documentType=${DocumentType.ROYALTY_STATEMENT}`,
            config
          )
        ]);
        setLedger(ledgerResponse.data);
        setStatements(statementsResponse.data);
        setIsLoading(false);
      };
      fetchData().catch(e => {
        if (axios.isCancel(e)) {
          // Silently return if the request was canceled because of unmounting
          return;
        }
        setHasError(true);
        setIsLoading(false);
      });
      return () => {
        source.cancel("Request cancelled by user");
      };
    }
  }, [payeeCode]);

  /**
   * Show loader if profile is not defined as well, because header is loading at this moment.
   * So show the content only when the whole page is ready.
   */
  if (isLoading || !profile) {
    return <Loader />;
  }

  if (hasError) {
    return (
      <div className={classNames("container", styles.container)}>
        <ResponseAlert
          className={styles.error}
          hideCloseButton
          onClick={() => setHasError(false)}
          type={ResponseAlertType.Error}
        >
          {t("common.unknownError")}
        </ResponseAlert>
      </div>
    );
  }

  return (
    <div className={classNames("container", styles.container, styles.row)}>
      <div className={styles.column}>
        <CurrentPeriod className={styles.widget} ledger={ledger} />
        <FinancialOverview
          className={styles.widget}
          ledger={ledger}
          periodsCount={8}
        />
      </div>
      <div className={styles.column}>
        <PriorPeriod
          className={styles.widget}
          ledger={ledger}
          onDownloadClick={onDownloadClick}
          statements={statements}
        />
        <LatestStatements
          className={styles.widget}
          count={2}
          onDownloadClick={onDownloadClick}
          statements={statements}
        />
        {/* Temporarily hide Recoupment Progress widget till next release */}
        {/*{ledger && (*/}
        {/*  <RecoupmentProgress className={styles.widget} ledger={ledger} />*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default Home;
