import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./RoyaltyPeroid.module.scss";
import { ReactComponent as ArrowDown } from "../../../../icons/arrow.svg";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar_vector.svg";

import {
  closedPeriodsSelector,
  dealsModalSelector,
  selectedClosedPeriodSelector
} from "../../../../store/dealsStore/selectors";
export interface RoyaltyPeriodProps {
  onClick?: () => void;
  royaltyPeriodRef?: any;
}
const RoyaltyPeriod = (props: RoyaltyPeriodProps) => {
  const { t, i18n } = useTranslation("translation");
  const selectedClosedPeriod = useSelector(selectedClosedPeriodSelector);
  const closedPeriods = useSelector(closedPeriodsSelector);
  const dealsModal = useSelector(dealsModalSelector);
  const RoyaltyDateFormatting = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      return (
        DateTime.fromJSDate(new Date(startDate))
          .setLocale(i18n.language)
          .toUTC()
          .toFormat("MMM yyyy") +
        " - " +
        DateTime.fromJSDate(new Date(endDate))
          .setLocale(i18n.language)
          .toUTC()
          .toFormat("MMM yyyy")
      );
    } else {
      const start = DateTime.utc().startOf("quarter");
      const end = DateTime.utc().endOf("quarter");

      const quarterStartDate = start
        .setLocale(i18n.language)
        .toFormat("MMM yyyy");
      const quarterEndDate = end.setLocale(i18n.language).toFormat("MMM yyyy");
      return quarterStartDate + " - " + quarterEndDate;
    }
  };
  return (
    <div
      className={cn(styles.block)}
      onClick={() => props.onClick?.()}
      ref={props.royaltyPeriodRef}
    >
      {!!closedPeriods?.length && (
        <CalendarIcon className={cn(styles.calendarIcon)} />
      )}
      <div
        className={cn(
          styles.text,
          closedPeriods?.length && styles.text_underline
        )}
      >
        <span>{t(`ledger.royaltyPeriod`) + ": "}</span>
        <br className={cn(styles.break)} />
        <span className={cn(styles.date)}>
          {RoyaltyDateFormatting(
            selectedClosedPeriod?.startDate,
            selectedClosedPeriod?.endDate
          )}
        </span>
      </div>
      {!!closedPeriods?.length && (
        <ArrowDown
          className={cn(
            styles.arrowIcon,
            dealsModal.type === "royaltyPeriod" && dealsModal.isOpen
              ? styles.arrowIcon_rotate
              : ""
          )}
        />
      )}
    </div>
  );
};
export default RoyaltyPeriod;
