import React from "react";

import styles from "./QuickFilterBar.module.scss";

import GridPlaceholderLoader from "../../../components/shared/GridPlaceholder";
import QuickFilter from "../../../components/shared/QuickFilterButton/QuickFilter";

type FilterBarType = {
  data?: {
    id: number;
    title: string;
    count: number;
    active?: boolean;
    disabled?: boolean;
  }[];
  fakeData: any;
  href?: string;
};

const QuickFilterBar: React.FC<FilterBarType> = ({ data, fakeData, href }) => {
  return (
    <div className={styles.filterBar}>
      {data
        ? data.map(el => (
            <QuickFilter
              title={el.title}
              count={el.count}
              active={el.active}
              key={el.id}
              href={href}
              disabled={el.disabled}
            />
          ))
        : fakeData.map((el: any) => (
            <GridPlaceholderLoader
              key={el}
              height={`${99 / 13}rem`}
              fullWidth
              width={`${145 / 13}rem`}
            />
          ))}
    </div>
  );
};

export default QuickFilterBar;
