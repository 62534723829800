import React from "react";
import { useSelector } from "react-redux";
import { RowClickedEvent } from "ag-grid-community";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "../UserManagement.module.scss";

import Condition from "../../../components/shared/Condition";
import DataTable from "../../../components/shared/DataTable";
import Chip, {
  IChip
} from "../../../components/userManagement/Filter/chip/Chip";
import FilterBar from "../../../components/userManagement/Filter/FilterBar";
import FilterByRange from "../../../components/userManagement/Filter/FilterByRange";
import Tabs from "../../../components/userManagement/Tabs/Tabs";
import UserDownloadBtn from "../../../components/userManagement/UserDownloadBtn";
import { useActions } from "../../../hooks/useActions";
import {
  dateRangeSelector,
  filtersSelector
} from "../../../store/architectUsersStore/selectors";
import { QuickFiltersData } from "../helper";
import QuickFilterBar from "../QuickFilterBar";

export interface GridProps {
  metaData?: any;
  tab: string;
  getGridData?: any;
  status?: string | undefined;
  quickFilters?: any;
  columnDefs: any;
  frameworkComponents?: any;
  userIdList?: any;
  selectedRows?: any;
  rowData?: any;
  setGridRef?: any;
  onBodyScroll?: any;
  total?: any;
  loading?: boolean;
  onRowClicked?: (e: RowClickedEvent) => void;
  onSortChanged?: (e: any) => void;
  downloadBtnClicked?: (e: any) => void;
  filterIconClicked?: () => void;
  onChangeSearch: (e: any) => void;
  changeSearchType?: () => void;
  searchClick: (e: any) => void;
  searchEmpty: (e: any) => void;
  searchValue?: string;
}

const Grid = ({
  metaData,
  tab,
  getGridData,
  status,
  quickFilters,
  columnDefs,
  frameworkComponents,
  userIdList,
  rowData,
  selectedRows,
  setGridRef,
  total,
  onRowClicked,
  onSortChanged,
  downloadBtnClicked,
  onChangeSearch,
  searchClick,
  searchValue,
  searchEmpty,
  filterIconClicked,
  changeSearchType,
  loading
}: GridProps) => {
  const filters = useSelector(filtersSelector);
  const {
    SetFilters,
    SetDateRange,
    setModalFilter,
    SetSearchedValue
  } = useActions();
  const dateRange = useSelector(dateRangeSelector);
  const handleClick = (type: string, itemValue?: string) => {
    if (type === "modifiedAt") {
      SetDateRange(null);
    } else
      SetFilters(filters.filter((el: IChip) => el.itemValue !== itemValue));
  };

  return (
    <>
      <Tabs metaData={metaData && metaData} tab={tab} />
      <FilterByRange />
      <div className={styles.filtersContainer}>
        {metaData ? (
          <QuickFilterBar
            data={QuickFiltersData(metaData, quickFilters, status)}
            fakeData={quickFilters}
            href={tab}
          />
        ) : (
          <QuickFilterBar fakeData={quickFilters} />
        )}
        <FilterBar
          hasSearchInput
          searchClick={searchClick}
          onChangeSearch={(val: string) => {
            onChangeSearch(val);
          }}
          searchValue={searchValue}
          searchEmpty={searchEmpty}
          onClick={filterIconClicked}
          changeSearchType={changeSearchType}
        />
      </div>
      <Condition
        condition={(searchValue || filters.length) && total}
        Truthy={
          <h1 className={styles.searchTotal}>{`${total} SEARCH RESULT`}</h1>
        }
      />
      <div className="d-flex justify-space-between">
        <div className={cn(styles.filter)}>
          {filters.length || dateRange ? (
            <>
              <div className={cn(styles.chips)}>
                {filters.map((el: IChip) => {
                  return (
                    <Chip
                      handleClick={() => handleClick(el.type, el.itemValue)}
                      type={el.type}
                      typeName={el.typeName}
                      label={el.label}
                      value={el.value}
                      itemValue={el.itemValue}
                      key={el.itemValue}
                    />
                  );
                })}
                {dateRange && (
                  <Chip
                    handleClick={() => handleClick("modifiedAt")}
                    type="modifiedAt"
                    typeName="Activity From"
                    value={`${dateRange?.startDate} - ${dateRange?.endDate}`}
                    label={`${DateTime.fromJSDate(
                      new Date(dateRange.startDate)
                    ).toFormat("yyyy/MM/dd")} - ${DateTime.fromJSDate(
                      new Date(dateRange?.endDate)
                    ).toFormat("yyyy/MM/dd")}`}
                    itemValue="modifiedAt"
                  />
                )}
                <div />
              </div>
              <div
                className={cn(styles.chips_clear)}
                onClick={() => {
                  SetDateRange(null);
                  SetFilters([]);
                  SetSearchedValue("");
                  setModalFilter([]);
                }}
              >
                Clear filter
              </div>
            </>
          ) : null}
        </div>

        {tab !== "activity" && status && tab !== "payee-code" && (
          <UserDownloadBtn
            click={(e: any) => downloadBtnClicked && downloadBtnClicked(e)}
          />
        )}
      </div>

      <DataTable
        total={total}
        tab={tab}
        status={status}
        frameworkComponents={frameworkComponents}
        rowHeight={55}
        headerHeight={46}
        columnDefs={columnDefs}
        themeClassName={cn(styles.grid)}
        getGridData={getGridData}
        // rowModelType="infinite"
        setGridRef={setGridRef}
        getGridHeight={(top: any) => `calc(100vh - ${top + 40}px)`}
        userIdList={userIdList}
        selectedRows={selectedRows}
        rowData={rowData}
        loading={loading}
        onRowClicked={e => onRowClicked && onRowClicked(e)}
        onSortChanged={e => onSortChanged && onSortChanged(e)}
      />
    </>
  );
};
export default Grid;
