import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom-v5-compat";
import { Tooltip } from "@material-ui/core";

import styles from "./Registration.module.scss";
import { ReactComponent as GlobeIcon } from "../../icons/globus.svg";
import { ReactComponent as InfoIcon } from "../../icons/info.svg";

import PageHeader from "../../components/shared/PageHeader";
import PhoneNumberInput from "../../components/shared/PhoneNumberInput";
import Button, { ButtonType } from "../../components/shared/UiButton";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import UiSelect from "../../components/shared/UiSelect";
import TextField from "../../components/shared/UiTextField";
import { axiosInstance } from "../../services/axios";
import { countrySelector } from "../../store/appStatic/selectors";
import { textParams } from "../../utils";
import { getErrorDetails } from "../../utils/error";
import { trimObject } from "../../utils/objects";
import {
  emailConfirmValidaton,
  emailValidaton,
  requiredValidaton
} from "../../utils/validation";
import { RegistrationFormItem } from "./Registration.types";

/**
 * Renders user registration form
 * @returns JSX element
 */
const Registration = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { control, formState, handleSubmit } = useForm<RegistrationFormItem>({
    mode: "all"
  });

  const countries = useSelector(countrySelector);
  const email = useWatch({ control, name: "email" });

  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const emailExistsError = t("registrationPage.errorEmailAlreadyExist");
  const validateEmail = emailValidaton(t("inputs.invalidEmail"));
  const validateEmailConfirmation = emailConfirmValidaton(
    t("registrationPage.errorEmailNotMatching"),
    email
  );
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const onCancel = (): void => {
    // Go back if there is a history, otherwise go to /home
    location.key === "default" ? navigate("/home") : navigate(-1);
  };

  const onSubmit = async (values: RegistrationFormItem): Promise<void> => {
    try {
      setError(null);
      const data = trimObject({
        countryCode: values.countryCode?.value,
        email: values.email,
        firstName: values.firstName,
        language: i18n.language,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber
      });
      await axiosInstance.post("/api/users/external", data);
      setIsSuccess(true);
    } catch (e) {
      const { status } = getErrorDetails(e);
      if (status === 412) {
        setError(emailExistsError);
        return;
      }
      setError(t("resetPasswordPage.errorMessageUnknown"));
    }
  };

  if (error && error !== emailExistsError) {
    return (
      <div className={styles.result}>
        <PageHeader className={styles.h1Result} hasGoldBorder variant="h1">
          {t("registrationPage.errorPopup.header")}
        </PageHeader>
        <div>
          {textParams(t("registrationPage.errorPopup.text"))[0]}{" "}
          <Link className={styles.link} to="/contact-us">
            {textParams(t("registrationPage.errorPopup.text"))[1]}
          </Link>
        </div>
        <Button
          className={styles.closeResultButton}
          onClick={() => navigate("/login")}
          type="button"
          variant={ButtonType.Primary}
        >
          {t("buttons.close")}
        </Button>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div className={styles.result}>
        <PageHeader className={styles.h1Result} hasGoldBorder variant="h1">
          {t("registrationPage.successPopup.header")}
        </PageHeader>
        <div>{t("registrationPage.successPopup.text")}</div>
        <Button
          className={styles.closeResultButton}
          onClick={() => navigate("/login")}
          type="button"
          variant={ButtonType.Primary}
        >
          {t("buttons.close")}
        </Button>
      </div>
    );
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <PageHeader className={styles.h1} variant="h1">
        {t("registrationPage.createNewAccount")}
      </PageHeader>
      {error && (
        <ResponseAlert
          className={styles.errorAlert}
          onClick={() => setError(null)}
          type={ResponseAlertType.Error}
        >
          <div>
            <p>{textParams(error)[0]}</p>
            <p className={styles.errorAlertHint}>
              <Link className={styles.link} to="/forgot-password">
                {textParams(error)[1]}
              </Link>{" "}
              {textParams(error)[2]}
              <Link className={styles.link} to="/login">
                {textParams(error)[3]}
              </Link>{" "}
              {textParams(error)[4]}
            </p>
          </div>
        </ResponseAlert>
      )}
      <div className={styles.formBody}>
        <Controller
          control={control}
          name="firstName"
          render={({ field, formState }) => (
            <TextField
              {...field}
              className={styles.input}
              errors={formState.errors}
              isReservedErrorSpace
              label={`${t("inputs.firstName")} *`}
              type="text"
            />
          )}
          rules={{ validate: validateRequired }}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field, formState }) => (
            <TextField
              {...field}
              className={styles.input}
              errors={formState.errors}
              isReservedErrorSpace
              label={`${t("inputs.lastName")} *`}
              type="text"
            />
          )}
          rules={{ validate: validateRequired }}
        />
        <Controller
          control={control}
          name="countryCode"
          render={({ field, formState }) => (
            <UiSelect
              {...field}
              className={styles.input}
              errors={formState.errors}
              isReservedErrorSpace
              label={`${t("inputs.dealSigningTerritories")} *`}
              labelIcon={
                <Tooltip title={t("registrationPage.locationTooltip")}>
                  <span className={styles.infoIconContainer}>
                    <InfoIcon className={styles.infoIcon} />
                  </span>
                </Tooltip>
              }
              options={countries}
              placeholder={`--${t("inputs.selectorPlaceholder")}--`}
              rightIcon={<GlobeIcon />}
            />
          )}
          rules={{ validate: validateRequired }}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field, fieldState }) => (
            <PhoneNumberInput
              disabled={field.disabled}
              error={fieldState.error?.message}
              isReservedErrorSpace
              label={t("inputs.phoneNumber")}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field, formState }) => (
            <TextField
              {...field}
              className={styles.input}
              errors={formState.errors}
              isReservedErrorSpace
              label={`${t("inputs.email")} *`}
              placeholder="user@example.com"
              type="text"
            />
          )}
          rules={{ validate: v => validateRequired(v) ?? validateEmail(v) }}
        />
        <Controller
          control={control}
          name="confirmEmail"
          render={({ field, formState }) => (
            <TextField
              {...field}
              className={styles.input}
              errors={formState.errors}
              isReservedErrorSpace
              label={`${t("inputs.confirmEmail")} *`}
              placeholder="user@example.com"
              type="text"
            />
          )}
          rules={{
            validate: v =>
              validateRequired(v) ??
              validateEmail(v) ??
              validateEmailConfirmation(v)
          }}
        />
      </div>
      <div className={styles.commandBar}>
        <div>
          {t("registrationPage.alreadyHaveAnAccount")}{" "}
          <Link className={styles.link} to="/login">
            {t("registrationPage.loginLink")}
          </Link>
        </div>
        <div className={styles.commandBarButtons}>
          <Button onClick={onCancel} type="button" variant={ButtonType.Text}>
            {t("buttons.cancel")}
          </Button>
          <Button
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
            type="submit"
            variant={ButtonType.Primary}
          >
            {t("buttons.create")}
          </Button>
        </div>
      </div>
      <div className={styles.info}>
        {textParams(t("registrationPage.info"))[0]}
        <Link className={styles.link} to="/terms-of-use">
          {textParams(t("registrationPage.info"))[1]}
        </Link>{" "}
        {textParams(t("registrationPage.info"))[2]}
        <Link className={styles.link} to="/privacy-policy">
          {textParams(t("registrationPage.info"))[3]}
        </Link>
      </div>
    </form>
  );
};

export default Registration;
