import React, { useCallback, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./SetDownloadReportPopup.module.scss";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar_vector.svg";

import { useActions } from "../../../../hooks/useActions";
import { TableType } from "../../../../page/StatementUpload/types";
import { downloadCSV } from "../../../../page/StatementUpload/utils";
import {
  downloadDateRangeModalSelector,
  downloadDateRangeSelector,
  downloadFilterModalSelector,
  downloadFiltersSelector
} from "../../../../store/architectUsersStore/selectors";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Chip, { IChip } from "../../../userManagement/Filter/chip/Chip";
import SelectField from "../../../userManagement/Filter/SelectField";
import Button from "../../../userManagement/WCMButton";
import Icon from "../../Icon";
import { sharedModalTypes } from "../Modals.constants";
import { ISelectItem } from "../SetFilterModal/FilterModal";

const SetDownloadReportPopup = () => {
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;

  const modal = useSelector(modalSelector);
  const {
    SetOpenModalAction,
    SetDownloadDateRangeModal,
    SetDownloadFilterModal,
    UploaderListAction
  } = useActions();
  const downloadFilters = useSelector(downloadFiltersSelector);
  const downloadFilterModal = useSelector(downloadFilterModalSelector);
  const downloadDateRange = useSelector(downloadDateRangeSelector);
  const downloadDateRangeModal = useSelector(downloadDateRangeModalSelector);
  const openPickerHandler = useCallback(() => {
    SetOpenModalAction({
      isOpen: true,
      type: [
        sharedModalTypes.RANGE_DATE_PICKER_MODAL,
        sharedModalTypes.DOWNLOAD_REPORT_MODAL
      ]
    });
  }, [SetOpenModalAction]);
  const { handleDownloadReport } = downloadCSV;

  const { uploaderList } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const closeReportModalHandler = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: [] });
  }, [SetOpenModalAction]);

  const clearPickerDataHandler = useCallback(() => {
    SetDownloadDateRangeModal(null);
  }, [SetDownloadDateRangeModal]);
  const upLoaders = uploaderList.map((el: any) => {
    return {
      value: el.email,
      label: el.title,
      name: "userTitle",
      typeName: "Uploader",
      itemValue: `Uploader_${el.email}`,
      operation: "in"
    };
  });
  const handleChange = useCallback(
    (items: ISelectItem[], type: string, typeName: string) => {
      const filterList = items.map((itm: ISelectItem) => {
        return {
          ...itm,
          type: type,
          typeName: typeName,
          itemValue: `${type}_${itm.value}`
        };
      });
      SetDownloadFilterModal([
        ...downloadFilterModal.filter((el: IChip) => el.type !== type),
        ...filterList
      ]);
    },
    [downloadFilters, downloadFilterModal]
  );

  const isOpen =
    modal.isOpen && modal.type.includes(sharedModalTypes.DOWNLOAD_REPORT_MODAL);

  useEffect(() => {
    if (isOpen) {
      UploaderListAction("PUBLISHED");
      SetDownloadFilterModal(downloadFilters);
      SetDownloadDateRangeModal(downloadDateRange);
    }
  }, [isOpen]);

  const userName = downloadFilterModal.map((el: any) => el.value);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeReportModalHandler}
      className={cn(styles.modal)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <div className={styles.title}>Download Report</div>
        <div onClick={closeReportModalHandler}>
          <Icon className={styles.closeIcon} kind="close" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={cn(styles.fieldWrapper)}>
          <p className={cn(styles.fieldLabel)}>Upload Date</p>
          <div className={cn(styles.field)} onClick={openPickerHandler}>
            {downloadDateRangeModal !== null ? (
              <Chip
                handleClick={clearPickerDataHandler}
                type="uploadedAt"
                label={`${DateTime.fromJSDate(
                  new Date(downloadDateRangeModal?.startDate)
                )
                  .toFormat("d MMM yyyy")
                  .toUpperCase()} - ${DateTime.fromJSDate(
                  new Date(downloadDateRangeModal?.endDate)
                )
                  .toFormat("d MMM yyyy")
                  .toUpperCase()}`}
                itemValue="uploadedAt"
              />
            ) : (
              "Start date - End date"
            )}
            <CalendarIcon className={cn(styles.calendar)} />
          </div>
        </div>

        <div className={cn(styles.fieldWrapper)}>
          <p className={cn(styles.fieldLabel)}>Uploader</p>
          <SelectField
            isSearchable
            options={upLoaders}
            placeholder="Select uploader"
            onChange={(item: ISelectItem[]) =>
              handleChange(item, "userTitle", "Uploader")
            }
            defaultValue={downloadFilterModal.filter(
              (el: IChip) => el.type === "userTitle"
            )}
            value={downloadFilterModal.filter(
              (el: IChip) => el.type === "userTitle"
            )}
          />
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <Button
          disabled={!downloadDateRangeModal && !downloadFilterModal.length}
          // variant={ButtonType.Primary}
          onClick={() => {
            closeReportModalHandler();
            handleDownloadReport(
              downloadDateRangeModal,
              userName,
              initialTableType
            );
          }}
        >
          Download
        </Button>
      </div>
    </Modal>
  );
};
export default SetDownloadReportPopup;
