import React from "react";
import cn from "classnames";

import styles from "./ManagementHeader.module.scss";
import { ReactComponent as Logo } from "../../../../icons/logo_black.svg";

import AlertBanner from "../../../../containers/AlertBanner";
import ManagementNavBar from "./ManagementNavBar";

const ManagementHeader = () => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.header)}>
        <div className={styles.logo}>
          <Logo />
          <h1 className={styles.title}>User management</h1>
        </div>
        <ManagementNavBar />
      </div>
      <AlertBanner hasFullWidthText />
    </div>
  );
};

export default ManagementHeader;
