import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

import { useActions } from "../../../hooks/useActions";

const PayeeCodeColumn = ({ data }: any) => {
  const { SetOpenModalAction } = useActions();

  const handleInpersonatePopupOpen = (data: any) => {
    const mappedUser = {
      ...data.user,
      payeeCode: data.payeeCode,
      impersonatePayeeCode: data.payeeCode,
      payeeAccount: data.payeeAccount,
      isPayee: true
    };
    SetOpenModalAction({ isOpen: true, type: "impersonate", user: mappedUser });
  };
  const payeeCode = data?.payeeCode;
  return payeeCode ? (
    <div className="d-flex flex-col h-full justify-center">
      <button
        onClick={() => {
          handleInpersonatePopupOpen(data);
        }}
        className={cn("text-13", styles.tag)}
      >
        {payeeCode}
      </button>
    </div>
  ) : (
    <div className="d-flex flex-col h-full justify-center"> - </div>
  );
};
export default PayeeCodeColumn;
