import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import cn from "classnames";
import i18n from "i18next";
import { DateTime } from "luxon";

import styles from "./SingleSongs.module.scss";
import { ReactComponent as Arrow } from "../../../icons/arrow.svg";
import { ReactComponent as Cursor } from "../../../icons/cursor.svg";

import CircleProgress from "../../../components/shared/CircleProgress/CircleProgress";
import InfoComponent from "../../../components/shared/InfoComponent";
import Loader from "../../../components/shared/Loading";
import SetCountryModal from "../../../components/shared/Modals/SetCountryModal";
import { getSortedData } from "../../../configs/utils";
import { useActions } from "../../../hooks/useActions";
import { payeeCodeSelector } from "../../../store/appStatic/selectors";
import {
  songsQueryOptionsSelector,
  worksLoadingSelector,
  worksSelector
} from "../../../store/dealsStore/selectors";
import { getUrlParams } from "../../../utils";

type SingleSongsType = {
  activeState: any;
  id: any;
  activeDeal: any;
};

const SingleSongs: React.FC<SingleSongsType> = ({
  activeState,
  id,
  activeDeal
}) => {
  const { t } = useTranslation("translation");
  const { deals } = useSelector((state: any) => state.dealsReducer);
  const history = useHistory();
  const works = useSelector(worksSelector);
  const params: any = useParams();
  const worksLoading = useSelector(worksLoadingSelector);
  const queryParams = getUrlParams(history.location.search);
  const workCode =
    queryParams?.workCode === "last"
      ? works?.result[0]?.workCode
      : queryParams?.workCode === "first"
      ? works?.result[works?.result.length - 1]?.workCode
      : queryParams?.workCode;
  const dealId =
    queryParams?.dealId === "last"
      ? works?.result[0]?.dealId
      : queryParams?.dealId === "first"
      ? works?.result[works?.result.length - 1]?.dealId
      : queryParams?.dealId;
  const currentDeal = activeDeal?.find(
    (el: { dealId: string }) => el.dealId === dealId
  );

  const payeeCode = useSelector(payeeCodeSelector);
  const [dropdownValue, setDropdownValue] = useState<any>({});
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { SetOpenModal, getWorks } = useActions();
  const songsQueryOptions = useSelector(songsQueryOptionsSelector);

  const options = useMemo(() => {
    if (activeState?.works?.length) {
      const songs = activeState?.works.sort(function(a: any, b: any) {
        if (a.workTitle < b.workTitle) {
          return -1;
        }
        if (a.workTitle > b.workTitle) {
          return 1;
        }
        return 0;
      });
      return songs.map((work: any) => {
        return {
          label: (
            <div className={styles.selectLabel} key={styles.selectLabel}>
              <p className={styles.contractingParty}>{work.workTitle}</p>
            </div>
          ),
          code: work.workCode,
          value: work.workTitle,
          dealId: work.dealId || null
        };
      });
    }
    return [];
  }, [activeState?.works, works]);

  const isFirst =
    options[0]?.code === dropdownValue?.code && songsQueryOptions.page === 0;

  const isLast =
    options[options.length - 1]?.code == dropdownValue?.code &&
    options[options.length - 1]?.dealId === dropdownValue?.dealId &&
    songsQueryOptions?.page + 1 === works?.pageCount;

  const activeWork = useMemo(() => {
    if (activeState?.works.length && dropdownValue?.code) {
      return activeState?.works.find((el: any) => {
        return el.workCode === dropdownValue.code && el.dealId === dealId;
      });
    }
    return {};
  }, [dropdownValue, activeState?.works, dealId]);

  useEffect(() => {
    if (options.length) {
      const activeValue = options.find(
        (el: any) =>
          el.code === workCode.replaceAll("%20", " ") && el.dealId === dealId
      );
      setDropdownValue(activeValue);
    }
  }, [options, workCode]);
  useEffect(() => {
    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(mobile);
  }, [isMobile]);

  const handleRowClick = useCallback(
    (type: string) => {
      let i: number | null = null;
      let active = { code: "", dealId: "" };
      options.forEach((el: any, index: number) => {
        if (
          el.code === dropdownValue.code &&
          el.dealId === dropdownValue.dealId
        ) {
          active = type === "back" ? options[index - 1] : options[index + 1];
          i = type === "back" ? index - 1 : index + 1;
        }
      });
      const isLastPage =
        i === options.length &&
        type !== "back" &&
        songsQueryOptions.page + 1 !== works.pageCount;

      const isFirstPage =
        i === -1 && type === "back" && songsQueryOptions.page > 0;
      if (isFirstPage) {
        getWorks({
          query: {
            ...songsQueryOptions,
            page: songsQueryOptions.page - 1
          },
          params: { deals: params.id }
        });
        history.push(`?workCode=first&dealId=first`);
      }

      if (isLastPage) {
        getWorks({
          query: {
            ...songsQueryOptions,
            page: songsQueryOptions.page + 1
          },
          params: { deals: params.id }
        });
        history.push(`?workCode=last&dealId=last`);
      }
      if (active && !isLastPage && !isFirstPage) {
        history.push(
          `?workCode=${encodeURI(active.code)}&dealId=${active.dealId}`
        );
      }
    },
    [dropdownValue, options, history, payeeCode, songsQueryOptions, works]
  );

  const total = useMemo(() => {
    let count = 0;
    if (activeWork?.creators) {
      activeWork?.creators.forEach((el: any) => {
        count = count + el.contribution;
      });
    }
    return count;
  }, [activeWork?.creators]);

  const songName = useMemo(() => {
    return (
      deals?.deals?.find((el: any) => el.dealId === activeWork?.dealId)
        ?.contractingParty || ""
    );
  }, [activeWork?.dealId, deals?.deals]);

  if (!activeState?.works || !activeWork?.workCode) {
    return null;
  }
  const RenderAlternativeNameAndLang = (
    data: {
      isNonRomanTitle: any;
      languageCode: string | null;
      title: string | null;
    }[]
  ) => {
    return (
      <div className={cn(styles.table)}>
        <div className={cn(styles.table_row)}>
          <div className={cn(styles.table_col, styles.table_col_title)}>
            <InfoComponent
              title={t(`dealAndSongs.alternativeNames`)}
              hasIcon
              titleSize="middle"
              information={t(`dealAndSongs.alternativeNameTooltip`)}
              blockClassName={cn(styles.alternativeName)}
            />
          </div>
          <div className={cn(styles.table_col, styles.table_col_title)}>
            <InfoComponent
              title={t(`dealAndSongs.alternativeLanguages`)}
              hasIcon={false}
              blockClassName={cn(styles.alternativeName)}
            />
          </div>
        </div>
        {data.map((el: any) => {
          return (
            <div className={cn(styles.table_row)} key={el.title}>
              <div className={cn(styles.table_col)}>
                <p className={cn(styles.table_col_text)}>{el.title || "-"}</p>
              </div>
              <div className={cn(styles.table_col)}>
                <p className={cn(styles.table_col_text)}>
                  {el?.languageCode
                    ? t(`language.langs.${el.languageCode}`)
                    : "-"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  if (worksLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className={"container"}>
        <div className={styles.filters}>
          <button
            className={cn(styles.backButton)}
            onClick={() => {
              if (!id) {
                history.push(`/songs`);
              } else {
                history.push(`/home/${id}`);
              }
            }}
            role="button"
          >
            <Cursor className={styles.backIcon} />
            <p>{t(`buttons.back`)}</p>
          </button>
          <div className={styles.actions}>
            <button
              className={cn(styles.wrapButton, { [styles.disabled]: isFirst })}
              onClick={() => handleRowClick("back")}
              role="button"
            >
              <Arrow className={styles.back} />
              <p>{t(`buttons.previousSong`)}</p>
            </button>
            <button
              className={cn(styles.wrapButton, { [styles.disabled]: isLast })}
              onClick={() => handleRowClick("next")}
              // unselectable="off"
            >
              <p>{t(`buttons.nextSong`)}</p>
              <Arrow className={styles.next} />
            </button>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.inormation}>
            <p className={styles.songLabel}>{dropdownValue?.label}</p>
            <div className={styles.infoWrap}>
              <InfoComponent
                title={t(`dealAndSongs.workId`)}
                titleSize="big"
                hasIcon={false}
              >
                <span className={cn(styles.infoText)}>
                  {activeWork?.workCode}
                </span>
              </InfoComponent>

              <InfoComponent
                title={t(`dealAndSongs.deliveryDate`)}
                hasIcon={true}
                iconSize="big"
                titleSize="big"
                information={t(`dealAndSongs.deliveryDateTooltip`)}
              >
                <span className={cn(styles.infoText)}>
                  {activeWork?.deliveryDate
                    ? DateTime.fromJSDate(new Date(activeWork?.deliveryDate))
                        .toUTC()
                        .setLocale(i18n.language)
                        .toLocaleString(DateTime.DATE_MED)
                    : "-"}
                </span>
              </InfoComponent>
            </div>
            <div className={styles.infoWrapSingle}>
              <InfoComponent
                title={t(`dealAndSongs.contractingParty`)}
                titleSize="big"
                hasIcon={false}
              >
                <span className={cn(styles.infoText)}>
                  {`${id ? activeState?.contractingParty : songName}` || "-"}
                </span>
              </InfoComponent>
            </div>
            <div className={styles.infoWrap}>
              <InfoComponent
                title={t(`dealAndSongs.dealId`)}
                hasIcon={true}
                iconSize="big"
                titleSize="big"
                information={t(`dealAndSongs.dealIdTooltip`)}
              >
                <span className={cn(styles.infoText)}>
                  {id ? id : activeWork?.dealId}
                </span>
              </InfoComponent>

              <InfoComponent
                title={t(`dealAndSongs.administeredTerritory`)}
                hasIcon={true}
                iconSize="big"
                titleSize="big"
                information={t(`dealAndSongs.administeredTerritoryTooltip`)}
              >
                <span
                  className={cn(styles.infoText, styles.infoText_underline)}
                  onClick={() => {
                    id
                      ? !!activeState?.territories?.length &&
                        activeState?.territories?.length > 5 &&
                        SetOpenModal({ isOpen: true, type: "countryList" })
                      : !!currentDeal?.territories?.length &&
                        currentDeal?.territories?.length > 5 &&
                        SetOpenModal({ isOpen: true, type: "countryList" });
                  }}
                >
                  {id
                    ? !!activeState?.territories?.length &&
                      activeState?.territories?.length > 1
                      ? `${activeState?.territories?.length} ${t(
                          "common.territories"
                        )}`
                      : activeState?.territories?.length === 1
                      ? activeState?.territories[0]
                      : "-"
                    : !!currentDeal?.territories?.length &&
                      currentDeal?.territories?.length > 1
                    ? `${currentDeal?.territories?.length} ${t(
                        "common.territories"
                      )}`
                    : currentDeal?.territories?.length === 1
                    ? currentDeal?.territories[0]
                    : "-"}
                </span>
              </InfoComponent>
            </div>
          </div>

          <div className={styles.procent}>
            <div className={styles.header}>
              <InfoComponent
                title={t(`dealAndSongs.contributors`)}
                hasIcon
                titleSize="big"
                information={t(`dealAndSongs.contributorsTooltip`)}
              />
            </div>
            {getSortedData(activeWork?.creators, {
              sort: "contribution",
              order: true
            })?.map((el: any) => (
              <div key={el.name} className={styles.lane}>
                <div>{el.name}</div>
                <div>{t(`dealAndSongs.creatorRoles.${[el.role]}`)}</div>
                <div>
                  <CircleProgress percent={el.contribution.toFixed(3)} />
                </div>
              </div>
            ))}
            <div className={styles.total}>
              {activeWork ? `${t("common.total")}: ${total.toFixed(3)} %` : ""}
            </div>
            {RenderAlternativeNameAndLang(activeWork?.altTitles)}
          </div>
        </div>
      </div>
      {id ? (
        <>
          {!!activeState?.territories?.length &&
            activeState?.territories?.length > 5 && (
              <SetCountryModal
                countries={activeState?.territories}
                modalTitle={activeWork?.workTitle}
              />
            )}
        </>
      ) : (
        <>
          {!!currentDeal?.territories?.length &&
            currentDeal?.territories?.length > 5 && (
              <SetCountryModal
                countries={currentDeal?.territories}
                modalTitle={activeWork?.workTitle}
              />
            )}
        </>
      )}
    </>
  );
};

export default SingleSongs;
