import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import cn from "classnames";

import styles from "./Tabs.module.scss";

import { useActions } from "../../../hooks/useActions";
import { UMSTypeSearchEnum } from "../../../page/UserManagement/types";
import { UMSTypeSearchSelector } from "../../../store/architectUsersStore/selectors";

export interface TabItemProps {
  value: string;
  metaData?: any;
  label: string;
  status?: string;
  active?: boolean;
}

const TabItem = ({ active, value, label }: TabItemProps) => {
  const { SetUMSTypeSearch } = useActions();
  const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
  const history = useHistory();
  const resetUMSTypeSearch = (tab: string) => {
    if (tab === "payee-code") {
      if (
        [
          UMSTypeSearchEnum.PAYEE_CODE,
          UMSTypeSearchEnum.PAYEE_ACCOUNT
        ].includes(UMSTypeSearch.value)
      )
        return;
      SetUMSTypeSearch({
        label: "Payee Code",
        value: UMSTypeSearchEnum.PAYEE_CODE
      });
    } else {
      if (
        [UMSTypeSearchEnum.USER_EMAIL, UMSTypeSearchEnum.USER_NAME].includes(
          UMSTypeSearch.value
        )
      )
        return;

      SetUMSTypeSearch({
        label: "User Name",
        value: UMSTypeSearchEnum.USER_NAME
      });
    }
  };
  return (
    <li
      className={`${cn(styles.item, active ? styles.active : "")}`}
      onClick={() => {
        resetUMSTypeSearch(value);
        history.push(`/user-management/${value}`);
      }}
    >
      <span>{label}</span>
    </li>
  );
};
export default TabItem;
