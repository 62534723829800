import { OktaAuth } from "@okta/okta-auth-js";

const ssrStorage = new Map<string, unknown>();

export const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER ?? "http://localhost",
  pkce: true,
  redirectUri: "/login/callback",
  scopes: ["offline_access", "openid", "roles"],
  tokenManager: {
    autoRenew: true,
    secure: true,
    // Can't use localStorage as a token storage in SSR. Create a dummy storage to fix console warning.
    storage:
      typeof window === "undefined"
        ? {
            /**
             * Gets value from SSR storage
             * @param key Storage key
             * @returns Storage value
             */
            getItem: key => ssrStorage.get(key),
            /**
             * Sets value in SSR storage
             * @param key Storage key
             * @param value New value
             */
            setItem: (key, value) => {
              ssrStorage.set(key, value);
            }
          }
        : window.localStorage
  }
});
