import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./Filter.module.scss";
import { ReactComponent as CalendarIcon } from "../../../icons/orange_calendar.svg";

import { useActions } from "../../../hooks/useActions";
import {
  bodySelector,
  dateRangeSelector,
  generatedFiltersSelector
} from "../../../store/architectUsersStore/selectors";
import RangeDatePicker from "../RangeDatePicker/RangeDatePicker";
const FilterByRange = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { meta, filters } = useSelector(
    (state: any) => state.architectUsersReducer
  );

  const body = useSelector(bodySelector);
  const dateRange = useSelector(dateRangeSelector);
  const generatedFilters = useSelector(generatedFiltersSelector);
  const { SetDateRange, GenerateFilter, SetSearchedValue } = useActions();
  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleChange = useCallback(
    (e: { endDate: string; startDate: string; label?: string }) => {
      const dateRangeFilter = {
        ...e,
        filterDateRange: [
          {
            name: "modifiedAt",
            operation: "ge",
            value: DateTime.fromJSDate(new Date(e.startDate)).toFormat(
              "yyyy-MM-dd"
            )
          },
          {
            name: "modifiedAt",
            operation: "le",
            value: DateTime.fromJSDate(new Date(e.endDate)).toFormat(
              "yyyy-MM-dd"
            )
          }
        ]
      };
      SetDateRange(dateRangeFilter);
      if (
        new Date(e.endDate).getTime() ===
          new Date(meta?.modifiedAtLatest).getTime() &&
        new Date(e.startDate).getTime() ===
          new Date(meta?.modifiedAtOldest).getTime()
      ) {
        SetDateRange(null);
      }
      setOpen(false);
    },
    [body, dateRange]
  );
  useEffect(() => {
    SetSearchedValue("");
    GenerateFilter(
      generatedFilters
        .filter((el: any) => el?.name !== "modifiedAt")
        .concat(dateRange?.filterDateRange || [])
    );
  }, [dateRange, filters]);
  const today = new Date();
  return (
    <div className={cn(styles.component)}>
      <p className={cn(styles.label)}>
        <b>Viewing activity from</b>
        <span onClick={toggleOpen} className={cn(styles.pointer)}>
          {(dateRange?.endDate ||
            dateRange?.startDate ||
            meta?.modifiedAtOldest ||
            meta?.modifiedAtOldest) && (
            <b className={cn(styles.underline)}>
              {DateTime.fromJSDate(
                new Date(dateRange?.startDate || meta?.modifiedAtOldest)
              ).toLocaleString(DateTime.DATE_SHORT)}{" "}
              -{" "}
              {DateTime.fromJSDate(
                new Date(dateRange?.endDate || meta?.modifiedAtLatest)
              ).toLocaleString(DateTime.DATE_SHORT)}
            </b>
          )}
          <CalendarIcon />
        </span>
      </p>
      <RangeDatePicker
        initialDateRange={{
          startDate: dateRange?.startDate || new Date(),
          endDate: dateRange?.endDate || new Date()
        }}
        open={open}
        onChange={handleChange}
        toggle={toggleOpen}
        defineRanges={[
          {
            startDate: new Date(new Date().setDate(today.getDate() - 7)),
            endDate: new Date(),
            label: "Last 7 Days"
          },
          {
            startDate: new Date(new Date().setDate(today.getDate() - 14)),
            endDate: new Date(),
            label: "Last 14 Days"
          },
          {
            startDate: new Date(new Date().setDate(today.getDate() - 30)),
            endDate: new Date(),
            label: "Last 30 Days"
          },
          {
            startDate: new Date(meta?.modifiedAtOldest),
            endDate: new Date(meta?.modifiedAtLatest),
            label: "Clear"
          }
        ]}
      />
    </div>
  );
};
export default FilterByRange;
