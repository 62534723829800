import "react-toastify/dist/ReactToastify.css";
import "../src/i18n/i18n";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Route, Switch, useHistory } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { StylesProvider, ThemeProvider } from "@material-ui/core";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";

import Layout from "./components/Layout";
import SecurityContainer from "./components/SecurityContainer";
import Loader from "./components/shared/Loading";
import Maintenance, { useMaintenance } from "./containers/Maintenance";
import PulseInsights from "./containers/PulseInsights";
import { useActions } from "./hooks/useActions";
import { oktaAuth } from "./lib/oktaAuth";
import AcceptTermsOfUse from "./page/AcceptTermsOfUse";
import AccessDenied from "./page/AccessDenied";
import Advances from "./page/Advances/Advances";
import AlertPreview from "./page/AlertPreview";
import Analytics from "./page/Analytics";
import { Announcements } from "./page/Announcements";
import Components from "./page/Components/Components";
import ContactUs from "./page/ContactUs";
import CookiesPolicy from "./page/CookiesPolicy";
import Deals from "./page/Deals/Deals";
import { Documents } from "./page/Documents";
import Faq from "./page/Faq";
import FaqMobile from "./page/Faq/mobile-web-view/Faq.mobile";
import Home from "./page/Home";
import InfoPrivacy from "./page/InfoPrivacy";
import LandingPage from "./page/LandingPage";
import ChangePassword from "./page/LoginPage/ChangePassword";
import CreatePassword from "./page/LoginPage/CreatePassword";
import ForgotPassword from "./page/LoginPage/ForgotPassword";
import Login from "./page/LoginPage/Login";
import Logout from "./page/LoginPage/Logout";
import NotFound from "./page/NotFound";
import PrivacyPolicy from "./page/PrivacyPolicy";
import PrivacyPolicyMobile from "./page/PrivacyPolicy/mobile-web-view/PrivacyPolicy.mobile";
import Profile from "./page/Profile";
import Registration from "./page/Registration";
import Settings from "./page/Settings";
import Songs from "./page/Songs";
import StatementUpload from "./page/StatementUpload/StatementUpload";
import TermsOfUse from "./page/TermsOfUse";
import TermsOfUseMobile from "./page/TermsOfUse/mobile-web-view/TermsOfUse.mobile";
import UserManagement from "./page/UserManagement/UserManagement";
import { profileSelector } from "./store/appStatic/selectors";
import { UserRole } from "./types/roles";
import { UserProfile } from "./types/userProfile";
import { theme } from "./utils/theme";
import { withSourceParam } from "./utils/url";

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const { isMaintenance, isMaintenanceLoading } = useMaintenance();
  const profile = useSelector<unknown, UserProfile | null>(profileSelector);
  const { resetProfileAction } = useActions();

  const restoreOriginalUri = (
    _oktaAuth: OktaAuth,
    originalUri: string
  ): void => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const onAuthRequired = (): void => {
    const isLogout = window.location.pathname
      .toLowerCase()
      .startsWith("/logout");
    const source = isLogout ? undefined : window.location.href;
    history.push(withSourceParam("/login", source));
    resetProfileAction();
  };

  useEffect(() => {
    document.body.style.backgroundColor = "black";
  }, []);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
    window.scrollTo({ top: 0 });
  }, [location]);

  if (isMaintenanceLoading) {
    return null;
  }

  if (isMaintenance) {
    return <Maintenance />;
  }

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={onAuthRequired}
          restoreOriginalUri={restoreOriginalUri}
        >
          <CompatRouter>
            <Layout>
              <Switch>
                <SecureRoute path="/" component={AcceptTermsOfUse} exact />
                <SecureRoute path="/accounts" component={LandingPage} exact />
                <SecureRoute path="/home" component={Home} exact />
                <SecureRoute path="/profile" component={Profile} exact />
                <SecureRoute path="/home/:id" component={Songs} exact />
                <SecureRoute path="/songs" component={Songs} exact />
                <SecureRoute path="/logout" component={Logout} exact />
                <Route path="/login" component={Login} exact />
                <Route path="/contact-us" component={ContactUs} exact />
                <Route
                  path="/forgot-password"
                  component={ForgotPassword}
                  exact
                />
                <Route
                  path="/reset-password"
                  component={CreatePassword}
                  exact
                />
                <Route
                  path="/change-password"
                  component={ChangePassword}
                  exact
                />
                <Route path="/registration" component={Registration} exact />
                <Route
                  path="/create-password"
                  component={CreatePassword}
                  exact
                />
                <Route path="/access-denied" component={AccessDenied} exact />
                <Route
                  path="/login/callback"
                  render={() => {
                    if (history.location.search.includes("access_denied")) {
                      history.push("/access-denied");
                    }
                    return <LoginCallback loadingElement={<Loader />} />;
                  }}
                />
                <Route path="/faq" component={Faq} exact />
                <Route path="/terms-of-use" component={TermsOfUse} exact />
                <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                <Route path="/cookies-policy" component={CookiesPolicy} exact />
                <Route path="/faq/mobile" component={FaqMobile} exact />
                <Route
                  path="/terms-of-use/mobile"
                  component={TermsOfUseMobile}
                  exact
                />
                <Route
                  path="/privacy-policy/mobile"
                  component={PrivacyPolicyMobile}
                  exact
                />
                <Route path="/info/privacy" component={InfoPrivacy} exact />
                <Route
                  path="/info/privacy/mobile"
                  render={() => <InfoPrivacy isMobileRoute />}
                  exact
                />
                <SecureRoute path="/settings" component={Settings} />
                <SecureRoute path="/analytics" component={Analytics} />
                <SecureRoute path="/documents" component={Documents} exact />
                <SecureRoute path="/advances" component={Advances} />
                <SecureRoute path="/deals" component={Deals} />
                <SecureRoute
                  path="/user-management/upload"
                  render={() => (
                    <SecurityContainer
                      roles={[UserRole.ADMIN, UserRole.SUPER_ADMIN]}
                      component={<StatementUpload />}
                    />
                  )}
                />
                <SecureRoute
                  path="/user-management/announcements"
                  component={Announcements}
                  exact
                />
                <SecureRoute
                  path={[
                    "/user-management/:tab",
                    "/user-management/:tab/:status",
                    "/user-management"
                  ]}
                  render={() => (
                    <SecurityContainer
                      roles={[UserRole.ADMIN, UserRole.SUPER_ADMIN]}
                      component={<UserManagement />}
                    />
                  )}
                  exact
                />
                <SecureRoute
                  path="/alert-preview"
                  component={AlertPreview}
                  exact
                />
                <Route path="/components" component={Components} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Layout>
          </CompatRouter>
          {profile?.type === "EXTERNAL" && <PulseInsights />}
        </Security>
      </ThemeProvider>
    </StylesProvider>
  );
};
export default App;
