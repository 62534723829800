const userTypes: any = {
  ACCOUNTANT: "Accountant",
  AGENT: "Agent",
  BUSINESS: "Business",
  LAWYER: "Lawyer",
  MANAGER: "Manager",
  PERSONAL: "Personal",
  PUB: "Pub (limited access)",
  WORK: "Work"
};

export { userTypes };
