import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import { AuthnTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import classNames from "classnames";

import styles from "./Login.module.scss";

import Loader from "../../../components/shared/Loading";
import PageHeader from "../../../components/shared/PageHeader";
import Button, { ButtonType } from "../../../components/shared/UiButton";
import CheckBoxFiled from "../../../components/shared/UiCheckBox";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../../components/shared/UiTextField";
import { getCookie, setCookie } from "../../../utils/cookies";
import { cleanupLocalStorage } from "../../../utils/localStorage";
import { trimObject } from "../../../utils/objects";
import { withSourceParam } from "../../../utils/url";
import { emailValidaton, requiredValidaton } from "../../../utils/validation";
import MfaForm from "../MfaForm";
import {
  SAVED_USERNAME_COOKIE,
  SAVED_USERNAME_COOKIE_EXPIRED
} from "./Login.constants";
import { LoginFormItem } from "./Login.types";

/**
 * Renders login page
 * @returns JSX element
 */
const Login = (): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const savedUsername = useMemo(() => getCookie(SAVED_USERNAME_COOKIE), []);

  const { control, formState, handleSubmit } = useForm<LoginFormItem>({
    defaultValues: {
      password: "",
      shouldSaveUsername: !!savedUsername,
      username: savedUsername ?? ""
    },
    mode: "all"
  });

  const [error, setError] = useState<null | string>(null);
  const [isIncorrectPw, setIsIncorrectPw] = useState(false);
  const [isLoading, setIsLoading] = useState<null | boolean>(false);
  const [mfaTransaction, setMfaTransaction] = useState<AuthnTransaction | null>(
    null
  );

  const originalUri = withSourceParam("/");
  const validateEmail = emailValidaton(t("inputs.invalidEmail"));
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const onMfaError = (): void => {
    setIsLoading(false);
    setMfaTransaction(null);
  };

  const onResponseAlertClose = (): void => {
    setError(null);
    setIsIncorrectPw(false);
  };

  const onSubmit = async (values: LoginFormItem): Promise<void> => {
    setError(null);
    setIsIncorrectPw(false);
    setIsLoading(true);
    try {
      const { password, shouldSaveUsername, username } = trimObject(values);
      setCookie(
        SAVED_USERNAME_COOKIE,
        username,
        shouldSaveUsername ? SAVED_USERNAME_COOKIE_EXPIRED : 0
      );
      const transaction = await oktaAuth.signInWithCredentials({
        password,
        username
      });
      if (transaction.status === "SUCCESS") {
        await oktaAuth.signInWithRedirect({
          originalUri,
          sessionToken: transaction.sessionToken
        });
        return;
      }
      if (
        transaction.status === "MFA_ENROLL" ||
        transaction.status === "MFA_ENROLL_ACTIVATE" ||
        transaction.status === "MFA_REQUIRED"
      ) {
        setMfaTransaction(transaction);
        return;
      }
      setError("resetPasswordPage.errorMessageUnknown");
      setIsLoading(false);
      window.NREUM.log(
        `CRITICAL: Authentication failed | ${transaction?.status} | user - ${username}`
      );
    } catch {
      setIsIncorrectPw(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate("/");
      return;
    }
    // Cleanup local storage settings from previous sessions
    cleanupLocalStorage();
  }, [authState?.isAuthenticated, navigate]);

  if (mfaTransaction) {
    return (
      <MfaForm
        onError={onMfaError}
        onSuccess={sessionToken =>
          oktaAuth.signInWithRedirect({ originalUri, sessionToken })
        }
        transaction={mfaTransaction}
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          className={styles.h2}
          subtitle={t("loginPage.accessMore")}
          variant="h2"
        >
          {t("loginPage.login")}
        </PageHeader>
        {(error || isIncorrectPw) && (
          <ResponseAlert
            className={styles.responseAlert}
            onClick={onResponseAlertClose}
            type={ResponseAlertType.Error}
          >
            <div>
              {error}
              {isIncorrectPw && (
                <Trans
                  components={{
                    a: (
                      <Link
                        className={styles.forgotLink}
                        to="/forgot-password"
                      />
                    ),
                    br: <br />
                  }}
                  i18nKey="loginPage.errorIncorrectEmailPassword"
                  t={t}
                />
              )}
            </div>
          </ResponseAlert>
        )}
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <TextField
              {...field}
              className={styles.emailField}
              label={t("inputs.email")}
              placeholder="user@example.com"
              type="text"
            />
          )}
          rules={{ validate: v => validateRequired(v) ?? validateEmail(v) }}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              {...field}
              className={styles.passwordField}
              label={t("inputs.password")}
              type="password"
            />
          )}
          rules={{ validate: validateRequired }}
        />
        <div
          className={classNames(styles.checkboxWrap, styles.checkboxContainer)}
        >
          <Controller
            control={control}
            name="shouldSaveUsername"
            render={({ field: { value, ...field } }) => (
              <CheckBoxFiled
                {...field}
                checked={value}
                className={styles.checkbox}
                label={t("loginPage.rememberMe")}
              />
            )}
          />
          <Link className={styles.troubleLoginLink} to="/forgot-password">
            {t("loginPage.troubleToLogin")}
          </Link>
        </div>
        <div
          className={classNames(
            styles.toolbar,
            ["LAS", "ES", "RU", "DE", "FR", "NL"].includes(i18n.language) &&
              styles.toolbarLangSpecific
          )}
        >
          <Link to="/registration">{t("loginPage.createNewAccount")}</Link>
          <Button
            className={styles.button}
            disabled={isLoading || !formState.isValid}
            loading={isLoading}
            type="submit"
            variant={ButtonType.Primary}
          >
            {t("buttons.login")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
