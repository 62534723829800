import React, { useCallback, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "../SetFilterModal/FilterModal.module.scss";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar_vector.svg";

import { GroupingFilters } from "../../../../configs/utils";
import { useActions } from "../../../../hooks/useActions";
import {
  modalSelector,
  savedUploadsDateRangeModalSelector,
  savedUploadsDateRangeSelector,
  savedUploadsFilterModalSelector,
  savedUploadsFiltersSelector
} from "../../../../store/architectUsersStore/selectors";
import Chip, { IChip } from "../../../userManagement/Filter/chip/Chip";
import SelectField from "../../../userManagement/Filter/SelectField";
import Button from "../../../userManagement/WCMButton";
import { ISelectItem } from "../SetFilterModal/FilterModal";

const SavedUploadsFilterModal = () => {
  const modal = useSelector(modalSelector);
  const savedUploadsFilterModal = useSelector(savedUploadsFilterModalSelector);
  const savedUploadsDateRange = useSelector(savedUploadsDateRangeSelector);
  const savedUploadsDateRangeModal = useSelector(
    savedUploadsDateRangeModalSelector
  );
  const savedUploadsFilters = useSelector(savedUploadsFiltersSelector);

  const {
    SetOpenModalAction,
    SetSavedUploadsFilterModal,
    GenerateSavedUploadsFilter,
    SetSavedUploadsFilters,
    SetSavedUploadsDateRangeModal,
    SetSavedUploadsDateRange
  } = useActions();
  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: [] });
  }, [SetOpenModalAction]);
  const { uploaderList } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const uploaders = uploaderList.map((el: any) => {
    return {
      value: el.email,
      label: el.title,
      name: "userEmail",
      typeName: "Uploader",
      itemValue: `Uploader_${el.email}`,
      operation: "in"
    };
  });
  const handleChange = useCallback(
    (items: ISelectItem[], type: string, typeName: string) => {
      const filterList = items.map((itm: ISelectItem) => {
        return {
          ...itm,
          type: type,
          typeName: typeName,
          itemValue: `${type}_${itm.value}`
        };
      });
      SetSavedUploadsFilterModal([
        ...savedUploadsFilterModal.filter((el: IChip) => el.type !== type),
        ...filterList
      ]);
    },
    [savedUploadsFilters, savedUploadsFilterModal]
  );
  useEffect(() => {
    GenerateSavedUploadsFilter(
      GroupingFilters(savedUploadsFilters).concat(
        savedUploadsDateRange?.filterDateRange || []
      )
    );
  }, [savedUploadsDateRange, savedUploadsFilters]);

  useEffect(() => {
    if (modal.isOpen && modal.type.includes("SavedUploadsFilterModal")) {
      SetSavedUploadsFilterModal(savedUploadsFilters);
      SetSavedUploadsDateRangeModal(savedUploadsDateRange);
    }
  }, [modal.isOpen]);

  const ApplyFilter = useCallback(() => {
    toggleModal();
    SetSavedUploadsFilters(savedUploadsFilterModal);
    SetSavedUploadsDateRange(savedUploadsDateRangeModal);
  }, [savedUploadsFilterModal, savedUploadsDateRangeModal]);
  const CancelFilter = useCallback(() => {
    toggleModal();
  }, [savedUploadsFilterModal, savedUploadsDateRangeModal]);
  const ClearFilter = useCallback(() => {
    SetSavedUploadsFilterModal([]);
    SetSavedUploadsDateRangeModal(null);
  }, [savedUploadsFilterModal, savedUploadsDateRangeModal]);
  const handleClick = () => {
    SetSavedUploadsDateRangeModal(null);
  };
  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type.includes("SavedUploadsFilterModal")}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal)}
        overlayClassName={styles.myoverlay}
      >
        <h1 className={cn(styles.title)}>Filters</h1>
        <div className={cn(styles.filterModal_field)}>
          <p className={cn(styles.filterModal_field_label)}>Upload Date</p>
          <div
            className={cn(styles.dateInput)}
            onClick={() => {
              SetOpenModalAction({
                isOpen: true,
                type: ["rangeDatePickerModal", "SavedUploadsFilterModal"]
              });
            }}
          >
            {savedUploadsDateRangeModal !== null ? (
              <Chip
                handleClick={() => handleClick()}
                type="uploadedAt"
                label={`${DateTime.fromJSDate(
                  new Date(savedUploadsDateRangeModal?.startDate)
                )
                  .toFormat("d MMM yyyy")
                  .toUpperCase()} - ${DateTime.fromJSDate(
                  new Date(savedUploadsDateRangeModal?.endDate)
                )
                  .toFormat("d MMM yyyy")
                  .toUpperCase()}`}
                itemValue="uploadedAt"
              />
            ) : (
              "--Select date--"
            )}
            <CalendarIcon className={cn(styles.calendar)} />
          </div>
        </div>
        <div className={cn(styles.filterModal_field)}>
          <p className={cn(styles.filterModal_field_label)}>Uploader</p>
          <SelectField
            isSearchable
            options={uploaders}
            placeholder="--Select uploader--"
            onChange={(item: ISelectItem[]) =>
              handleChange(item, "userEmail", "Uploader")
            }
            defaultValue={savedUploadsFilterModal.filter(
              (el: IChip) => el.type === "userEmail"
            )}
            value={savedUploadsFilterModal.filter(
              (el: IChip) => el.type === "userEmail"
            )}
          />
        </div>
        <div className={cn(styles.buttonList)}>
          <Button onClick={() => CancelFilter()} variant="text">
            Cancel
          </Button>
          <Button onClick={() => ClearFilter()} variant="text">
            Clear
          </Button>
          <Button onClick={() => ApplyFilter()} variant="outlined">
            Apply
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default SavedUploadsFilterModal;
