export interface FilterBarType {
  id: number;
  title: string;
  count: number;
  active?: boolean;
  disabled?: boolean;
}

export enum MetaDataObject {
  activeCount = "activeCount",
  inactiveCount = "inactiveCount",
  invitedCount = "invitedCount",
  registeredCount = "registeredCount",
  suspendedCount = "suspendedCount",
  semiAnualCount = "semiannuallyPayeeCounts",
  quarterlyCount = "quarterlyPayeeCounts"
}

export interface MetaDataType {
  activeCount?: number;
  inactiveCount?: number;
  registeredCount?: number;
  invitedCount?: number;
  suspendedCount?: number;
  semiannuallyPayeeCounts?: number;
  quarterlyPayeeCounts?: number;
}

const getQuickFilterCount = (name: MetaDataObject, metaData: MetaDataType) => {
  switch (name) {
    case MetaDataObject.activeCount:
      return metaData[name];
    case MetaDataObject.inactiveCount:
      return metaData[name];
    case MetaDataObject.registeredCount:
      return metaData[name];
    case MetaDataObject.invitedCount:
      return metaData[name];
    case MetaDataObject.suspendedCount:
      return metaData[name];
    case MetaDataObject.quarterlyCount:
      return metaData[name];
    case MetaDataObject.semiAnualCount:
      return metaData[name];
    default:
      return "all";
  }
};
const getQuickFilterName = (name: MetaDataObject) => {
  switch (name) {
    case MetaDataObject.activeCount:
      return "Active";
    case MetaDataObject.inactiveCount:
      return "Inactive";
    case MetaDataObject.registeredCount:
      return "Registered";
    case MetaDataObject.invitedCount:
      return "Invited";
    case MetaDataObject.suspendedCount:
      return "Suspended";
    case MetaDataObject.quarterlyCount:
      return "Quarterly";
    case MetaDataObject.semiAnualCount:
      return "Semianual";
    default:
      return "all";
  }
};

export const QuickFiltersData = (
  metaData: MetaDataType,
  quickFilters: Array<MetaDataObject>,
  status?: string
) => {
  return quickFilters.map(
    (el, id: number): FilterBarType => {
      return {
        id: id + 1,
        title: getQuickFilterName(el) as string,
        count: getQuickFilterCount(el, metaData) as number,
        active: status === getQuickFilterName(el)?.toLowerCase()
      };
    }
  );
};
export default { QuickFiltersData };
