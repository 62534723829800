import { ReactComponent as add_user } from "./add_user.svg";
import { ReactComponent as admin_history } from "./admin_history.svg";
import { ReactComponent as advance_calendar } from "./advance_calendar.svg";
import { ReactComponent as announcement } from "./announcement.svg";
import { ReactComponent as announcement_calendar } from "./announcement_calendar.svg";
import { ReactComponent as arrow } from "./arrow.svg";
import { ReactComponent as arrow_black } from "./arrow_black.svg";
import { ReactComponent as arrow_right } from "./arrow_right.svg";
import { ReactComponent as arrow_right_big } from "./arrow_right_big.svg";
import { ReactComponent as arrow_small } from "./arrow_small.svg";
import { ReactComponent as back_arrow } from "./back_arrow.svg";
import { ReactComponent as bin } from "./bin.svg";
import { ReactComponent as bin_warning } from "./bin_warning.svg";
import { ReactComponent as black_globus } from "./black_globus.svg";
import { ReactComponent as burger } from "./burger.svg";
import { ReactComponent as burger_menu } from "./burger_menu.svg";
import { ReactComponent as calendar } from "./calendar.svg";
import { ReactComponent as calendar_left_arrow } from "./calendar_left_arrow.svg";
import { ReactComponent as calendar_right_arrow } from "./calendar_right_arrow.svg";
import { ReactComponent as calendar_vector } from "./calendar_vector.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as check_square } from "./check_square.svg";
import { ReactComponent as checkmark_black } from "./checkmark_black.svg";
import { ReactComponent as chevron_down } from "./chevron_down.svg";
import { ReactComponent as chevron_right } from "./chevron_right.svg";
import { ReactComponent as chevron_right_small } from "./chevron_right_small.svg";
import { ReactComponent as chevrons_down } from "./chevrons_down.svg";
import { ReactComponent as chevrons_down_grey } from "./chevrons_down_grey.svg";
import { ReactComponent as chevrons_up } from "./chevrons_up.svg";
import { ReactComponent as chevrons_up_grey } from "./chevrons_up_grey.svg";
import { ReactComponent as clear } from "./clear.svg";
import { ReactComponent as clear_icon } from "./clear_icon.svg";
import { ReactComponent as clear_icon_hovered } from "./clear_icon_hovered.svg";
import { ReactComponent as clear_icon_hovered_light } from "./clear_icon_hovered_light.svg";
import { ReactComponent as clear_icon_light } from "./clear_icon_light.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as close_white_eye } from "./close_white_eye.svg";
import { ReactComponent as col_accent } from "./col_accent.svg";
import { ReactComponent as cursor } from "./cursor.svg";
import { ReactComponent as dark_cursor } from "./dark_cursor.svg";
import { ReactComponent as delete_icon } from "./delete_icon.svg";
import { ReactComponent as document_upload } from "./document_upload.svg";
import { ReactComponent as down_icon_dropdown } from "./down_icon_dropdown.svg";
import { ReactComponent as down_vector } from "./down_vector.svg";
import { ReactComponent as dwnld_btn } from "./dwnld_btn.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as edit_outlined } from "./edit_outlined.svg";
import { ReactComponent as edit_outlined_bold } from "./edit_outlined_bold.svg";
import { ReactComponent as email_white } from "./email_white.svg";
import { ReactComponent as exit } from "./exit.svg";
import { ReactComponent as file } from "./file.svg";
import { ReactComponent as file_csv } from "./file_csv.svg";
import { ReactComponent as file_dnld_icon } from "./file_dnld_icon.svg";
import { ReactComponent as file_dwnld } from "./file_dwnld.svg";
import { ReactComponent as file_pdf } from "./file_pdf.svg";
import { ReactComponent as file_check } from "./file-check.svg";
import { ReactComponent as filter } from "./filter.svg";
import { ReactComponent as filter_search } from "./filter_search.svg";
import { ReactComponent as gear } from "./gear.svg";
import { ReactComponent as globus } from "./globus.svg";
import { ReactComponent as globus_white } from "./globus_white.svg";
import { ReactComponent as leaf } from "./leaf.svg";
import { ReactComponent as line_icon } from "./line_icon.svg";
import { ReactComponent as loading } from "./loading.svg";
import { ReactComponent as log_in } from "./log_in.svg";
import { ReactComponent as logo } from "./logo.svg";
import { ReactComponent as logo_black } from "./logo_black.svg";
import { ReactComponent as logout } from "./logout.svg";
import { ReactComponent as loop } from "./loop.svg";
import { ReactComponent as modal_close } from "./modal_close.svg";
import { ReactComponent as new_profile_icon } from "./new_profile_icon.svg";
import { ReactComponent as notification_bell } from "./notification_bell.svg";
import { ReactComponent as orange_calendar } from "./orange_calendar.svg";
import { ReactComponent as percentage_arrow_down } from "./percentage_arrow_down.svg";
import { ReactComponent as percentage_arrow_up } from "./percentage_arrow_up.svg";
import { ReactComponent as plus } from "./plus.svg";
import { ReactComponent as plus_light } from "./plus_light.svg";
import { ReactComponent as profile_icon } from "./profile_icon.svg";
import { ReactComponent as question } from "./question.svg";
import { ReactComponent as save } from "./save.svg";
import { ReactComponent as scroll_top } from "./scroll_top.svg";
import { ReactComponent as search_thin } from "./search_thin.svg";
import { ReactComponent as show_black_eye } from "./show_black_eye.svg";
import { ReactComponent as show_white_eye } from "./show_white_eye.svg";
import { ReactComponent as sort } from "./sort.svg";
import { ReactComponent as sort_down } from "./sort_down.svg";
import { ReactComponent as sort_up } from "./sort_up.svg";
import { ReactComponent as square } from "./square.svg";
import { ReactComponent as success } from "./success.svg";
import { ReactComponent as success_circle } from "./success_circle.svg";
import { ReactComponent as up } from "./up.svg";
import { ReactComponent as user_icon } from "./user_icon.svg";
import { ReactComponent as user_lock } from "./user_lock.svg";
import { ReactComponent as user_plus } from "./user_plus.svg";
import { ReactComponent as user_reactivated } from "./user_reactivated.svg";
import { ReactComponent as user_revoked } from "./user_revoked.svg";
import { ReactComponent as user_revokeed } from "./user_revokeed.svg";
import { ReactComponent as user_role } from "./user_role.svg";
import { ReactComponent as user_slash } from "./user_slash.svg";
import { ReactComponent as warning } from "./warning.svg";
import { ReactComponent as warning_triangle } from "./warning_triangle.svg";
import { ReactComponent as wcm_onogram_lack } from "./wcm_monogram_black.svg";
import { ReactComponent as wcm_onogram_old } from "./wcm_monogram_gold.svg";
import { ReactComponent as white_glogus } from "./white_glogus.svg";
import { ReactComponent as white_info_icon } from "./white_info_icon.svg";
import { ReactComponent as white_info_icon_hovered } from "./white_info_icon_hovered.svg";
import { ReactComponent as x_2 } from "./x_2.svg";
import { ReactComponent as x_3 } from "./x_3.svg";
import { ReactComponent as x_octagon } from "./x_octagon.svg";
import { ReactComponent as x_octagon_warning } from "./x_octagon_warning.svg";

const Icons = {
  bin_warning,
  edit_outlined,
  edit_outlined_bold,
  x_octagon_warning,
  x_octagon,
  plus_light,
  arrow_right_big,
  calendar_left_arrow,
  calendar_right_arrow,
  announcement_calendar,
  plus,
  announcement,
  notification_bell,
  back_arrow,
  add_user,
  admin_history,
  advance_calendar,
  arrow,
  file_check,
  question,
  arrow_black,
  arrow_small,
  black_globus,
  burger,
  burger_menu,
  calendar,
  calendar_vector,
  check,
  check_square,
  checkmark_black,
  chevron_down,
  chevron_right,
  chevron_right_small,
  chevrons_down,
  chevrons_down_grey,
  chevrons_up,
  chevrons_up_grey,
  clear,
  clear_icon,
  clear_icon_hovered,
  clear_icon_hovered_light,
  clear_icon_light,
  close,
  close_white_eye,
  col_accent,
  cursor,
  dark_cursor,
  delete_icon,
  down_icon_dropdown,
  down_vector,
  dwnld_btn,
  edit,
  email_white,
  file_dnld_icon,
  file_dwnld,
  filter,
  filter_search,
  gear,
  globus,
  globus_white,
  leaf,
  line_icon,
  loading,
  log_in,
  logo,
  logo_black,
  logout,
  loop,
  modal_close,
  new_profile_icon,
  orange_calendar,
  percentage_arrow_down,
  percentage_arrow_up,
  profile_icon,
  scroll_top,
  search_thin,
  show_black_eye,
  show_white_eye,
  sort,
  sort_down,
  sort_up,
  square,
  success,
  up,
  user_icon,
  user_lock,
  user_plus,
  user_reactivated,
  user_revoked,
  user_revokeed,
  user_role,
  user_slash,
  warning,
  wcm_onogram_lack,
  wcm_onogram_old,
  white_glogus,
  white_info_icon,
  white_info_icon_hovered,
  x_2,
  x_3,
  success_circle,
  bin,
  warning_triangle,
  file_pdf,
  file_csv,
  file,
  save,
  arrow_right,
  document_upload,
  exit
};

export default Icons;
