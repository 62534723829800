import cn from "classnames";

import styles from "./styles.module.scss";

type AssociatedUser = {
  email: string;
  fullName?: string;
  id: string;
};

interface EmailColumnProps {
  user: AssociatedUser;
}

import React from "react";

const AssociatedUsers: React.FC<EmailColumnProps> = props => {
  if (!props.user?.id) {
    return null;
  }

  return (
    <div
      className={cn(
        "d-flex flex-col h-full justify-center",
        styles.assocuatedUsers
      )}
    >
      <div>{props.user.email}</div>
    </div>
  );
};

export default AssociatedUsers;
