import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import styles from "./NotFound.module.scss";

import PageHeader from "../../components/shared/PageHeader";
import Button, { ButtonType } from "../../components/shared/UiButton";

/**
 * Renders page not found component
 * @returns JSX element
 */
const NotFound = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={styles.notFound}>
        <PageHeader className={styles.h1} hasGoldBorder variant="h1">
          {t("pageNotFound.title")}
        </PageHeader>
        <div className={styles.description}>{t("pageNotFound.text")}</div>
        <Button
          className={styles.closeButton}
          onClick={() => navigate("/")}
          variant={ButtonType.Primary}
        >
          {t("buttons.close")}
        </Button>
        <div className={styles.info}>
          <Trans
            components={{
              a: <Link className={styles.link} to="/contact-us" />
            }}
            i18nKey="pageNotFound.bottomText"
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
