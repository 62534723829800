import React from "react";
import { useSelector } from "react-redux";

import { UMSTypeSearchEnum } from "../../../page/UserManagement/types";
import { UMSTypeSearchSelector } from "../../../store/architectUsersStore/selectors";
const TitleColumn = ({ data }: any) => {
  const { searchedValue } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const Marker = () => {
    const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
    const matchedValue = data?.title?.match(new RegExp(searchedValue, "gi"));
    if (UMSTypeSearch?.value !== UMSTypeSearchEnum.USER_NAME)
      return data?.title;
    return data?.title?.replace(
      new RegExp(searchedValue, "gi"),
      `<span class="searchMarked">${matchedValue ? matchedValue[0] : ""}</span>`
    );
  };
  return (
    <div className="d-flex flex-col h-full justify-center">
      <div className="text-13" dangerouslySetInnerHTML={{ __html: Marker() }} />
    </div>
  );
};
export default TitleColumn;
