import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { profileSelector } from "../../store/appStatic/selectors";
import { UserProfile } from "../../types/userProfile";
import Loader from "../shared/Loading";
import { SecurityContainerProps } from "./SecurityContainer.types";

/**
 * Secures access to components based on user role
 * @param props React component properties
 * @returns JSX element
 */
const SecurityContainer = (props: SecurityContainerProps): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const profile = useSelector<unknown, UserProfile | null>(profileSelector);

  useEffect(() => {
    if (profile?.role && !props.roles.includes(profile.role)) {
      navigate("/access-denied");
    }
  }, [profile?.role]);

  if (!profile?.role || !props.roles.includes(profile.role)) {
    // Don't show the loader for user management pages
    return pathname.startsWith("/user-management") ? <></> : <Loader />;
  }

  return <>{props.component}</>;
};

export default SecurityContainer;
