import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import axios from "axios";
import cn from "classnames";

import styles from "./RowToolTipItem.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import LoadingCircle from "../../../shared/LoadingCircle";

export interface RowToolTipItemProps {
  title: string | any;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  children?: JSX.Element;
  user?: any;
}

const RowToolTipItem = ({
  title,
  onClick,
  className,
  children,
  user
}: RowToolTipItemProps) => {
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const navigate = useNavigate();
  const {
    getLanguageAction,
    setProfileAction,
    SetOpenModalAction
  } = useActions();
  const handleImpersonate = useCallback(() => {
    if (user?.id) {
      setLoad(true);
      axios
        .get(`/api/users/${user.id}/clients`, {
          headers: {
            authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        })
        .then(res => {
          if (res?.data?.clients?.length) {
            localStorage.setItem("impersonateId", user.id);
            localStorage.setItem("impersonateEmail", user.email);
            localStorage.setItem("impersonateUser", JSON.stringify(user));
            getLanguageAction(user.id);
            setProfileAction(navigate, user.id);
            history.push("/accounts");
          }
        })
        .catch(() => {
          SetOpenModalAction({ isOpen: true, type: "impersonate" });
        })
        .finally(() => setLoad(false));
    }
  }, [user]);
  return (
    <div
      className={cn(
        styles.item,
        className ? className : "",
        "table-toolTip-item"
      )}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
        if (title === "Impersonate") {
          handleImpersonate();
        }
      }}
    >
      {title}
      {children}
      {load && <LoadingCircle />}
    </div>
  );
};
export default RowToolTipItem;
