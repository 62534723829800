import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Chart as ChartJS, ChartDataset, registerables } from "chart.js";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./FinancialOverview.module.scss";
import { ReactComponent as ChartIcon } from "../../../../icons/chart.svg";

import PageHeader from "../../../../components/shared/PageHeader";
import { convertToIntlLocale, formatCurrency } from "../../../../utils/format";
import { DATE_FORMAT } from "./FinancialOverview.constants";
import { FinancialOverviewProps } from "./FinancialOverview.types";

ChartJS.register(...registerables);

/**
 * Renders financial overview chart
 * @param props React component properties
 * @returns JSX element
 */
const FinancialOverview = (props: FinancialOverviewProps): JSX.Element => {
  const { className, ledger, periodsCount } = props;

  const isMdSize = useMediaQuery({ minWidth: 768 });
  const { i18n, t } = useTranslation("translation");

  const [view, setView] = useState<"balance" | "royalty">("royalty");

  const balances = useMemo(
    () => ledger?.financialOverviews?.slice(0, periodsCount).reverse() ?? [],
    [ledger, periodsCount]
  );

  const labels = useMemo(
    () =>
      balances.map(b =>
        DateTime.fromISO(b.endDate)
          .setLocale(convertToIntlLocale(i18n.language))
          .toFormat(DATE_FORMAT)
      ),
    [balances, i18n.language]
  );

  const { balanceData, royaltyData } = useMemo(
    () =>
      balances.reduce<{
        balanceData: number[];
        royaltyData: number[];
      }>(
        (result, balance) => ({
          balanceData: [...result.balanceData, balance.closingBalance],
          royaltyData: [...result.royaltyData, balance.royaltyEarnings]
        }),
        { balanceData: [], royaltyData: [] }
      ),
    [balances]
  );

  const balanceDataset: ChartDataset<"line", number[]> = {
    backgroundColor: "#ad076a",
    borderColor: "#ad076a",
    data: balanceData,
    label: t("ledger.closingBalance")
  };

  const royaltyDataset: ChartDataset<"line", number[]> = {
    backgroundColor: "#007bd9",
    borderColor: "#007bd9",
    data: royaltyData,
    label: t("home.netEarnings")
  };

  const balanceRadioLabelClassName = classNames(
    styles.radioLabel,
    view === "balance" && styles.balanceRadioLabelChecked
  );
  const royaltyRadioLabelClassName = classNames(
    styles.radioLabel,
    view === "royalty" && styles.royaltyRadioLabelChecked
  );

  if (balances.length < 2 || !ledger) {
    return (
      <div className={classNames(styles.container, className)}>
        <PageHeader className={styles.h2} variant="h2">
          {t("home.financialOverview")}
        </PageHeader>
        <div className={styles.description}>
          {t("home.financialOverviewDescription")}
        </div>
        <div className={styles.emptyChart}>
          <ChartIcon className={styles.chartIcon} />
          <div className={styles.emptyChartText}>
            <div>{t("home.noDataToShowYet")}</div>
            <div className={styles.emptyChartDescription}>
              {t("home.noDataFinOverviewDescription")}
            </div>
          </div>
        </div>
        <div className={styles.fieldset}>
          <div className={styles.emptyChartRadioLabel} />
          <div className={styles.emptyChartRadioLabel} />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <PageHeader className={styles.h2} variant="h2">
        {t("home.financialOverview")}
      </PageHeader>
      <div className={styles.description}>
        {t("home.financialOverviewDescription")}
      </div>
      <div className={styles.chartContainer}>
        <Line
          data={{
            datasets: view === "balance" ? [balanceDataset] : [royaltyDataset],
            labels
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: {
                backgroundColor: "#383838",
                borderColor: "white",
                borderWidth: 1,
                callbacks: {
                  label: ({ dataset: { label }, raw }) =>
                    `${label}: ${formatCurrency(raw, ledger.currency)}`
                },
                caretPadding: 8,
                cornerRadius: 0,
                displayColors: false,
                padding: 10
              }
            },
            scales: {
              x: {
                grid: {
                  borderColor: "#ffffff1f",
                  borderWidth: 2,
                  drawBorder: true,
                  tickColor: "#ffffff1f"
                },
                ticks: {
                  color: "#878787",
                  font: { size: 9 },
                  maxRotation: 0,
                  maxTicksLimit: isMdSize ? undefined : 4,
                  padding: 10
                }
              },
              y: {
                beginAtZero: true,
                grid: { color: "#ffffff1f", drawTicks: false },
                ticks: {
                  callback: v => formatCurrency(v, ledger.currency),
                  color: "#878787",
                  font: { size: 9 },
                  maxTicksLimit: 6,
                  padding: 15
                }
              }
            }
          }}
        />
      </div>
      <fieldset className={styles.fieldset}>
        <label className={royaltyRadioLabelClassName}>
          <div>
            {royaltyDataset.label}
            <div className={styles.radioLabelDescription}>
              {t("home.netEarningsTooltip")}
            </div>
          </div>
          <input
            checked={view === "royalty"}
            className={styles.radioInput}
            name="royalty"
            onChange={() => setView("royalty")}
            type="radio"
          />
        </label>
        <label className={balanceRadioLabelClassName}>
          <div>
            {balanceDataset.label}
            <div className={styles.radioLabelDescription}>
              {t("home.closingBalanceDescription")}
            </div>
          </div>
          <input
            checked={view === "balance"}
            className={styles.radioInput}
            name="balance"
            onChange={() => setView("balance")}
            type="radio"
          />
        </label>
      </fieldset>
    </div>
  );
};

export default FinancialOverview;
