import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./LedgerBlock.module.scss";
import { ReactComponent as DnwldIcon } from "../../../icons/file_dwnld.svg";

import { useActions } from "../../../hooks/useActions";
import {
  clientsSelector,
  singleClientSelector
} from "../../../store/appStatic/selectors";
import { closedPeriodsSelector } from "../../../store/dealsStore/selectors";
import SetRoyaltyPeriodModal from "../Modals/SetRoyaltyPeriodModal";
import Button, { ButtonType } from "../UiButton";
import RoyaltyPeriod from "./RoyaltyPeroid";
const LedgerBlock = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const clients = useSelector(clientsSelector);
  const closedPeriods = useSelector(closedPeriodsSelector);
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const singleClient = useSelector(singleClientSelector);
  const { SetOpenModal, SetSelectedClosedPeriod } = useActions();
  const labelRef = useRef<any>(null);

  useEffect(() => {
    if (closedPeriods.length) {
      const date = localStorage.getItem("selectedDate");
      let previouslySelectedClosedPeriod: any = null;
      if (date) {
        const parsedDate = JSON.parse(
          localStorage.getItem("selectedDate") || ""
        );
        previouslySelectedClosedPeriod = closedPeriods.find(
          (closedPeriod: any) =>
            `${closedPeriod.startDate} - ${closedPeriod.endDate}` ===
            parsedDate.value
        );
      }
      SetSelectedClosedPeriod(
        previouslySelectedClosedPeriod || closedPeriods[0]
      );
    }
  }, [history.location.pathname, closedPeriods]);

  return (
    <>
      <div>
        <div
          className={cn(
            styles.flex,
            singleClient?.data && singleClient?.data?.emailType?.includes("PUB")
              ? styles.flex_noSticky
              : ""
          )}
        >
          {!!clients?.length && !!closedPeriods.length && (
            <div className={cn(styles.ledger_block)}>
              <div className={cn(styles.wrap, styles.wrap_ledger)}>
                <RoyaltyPeriod
                  royaltyPeriodRef={labelRef}
                  onClick={() => {
                    closedPeriods.length &&
                      SetOpenModal({
                        isOpen: true,
                        type: "royaltyPeriod"
                      });
                  }}
                />
              </div>
              <Button
                variant={ButtonType.Primary}
                className={styles.button}
                onClick={() =>
                  SetOpenModal({
                    isOpen: true,
                    type: "downloadStatementModal"
                  })
                }
              >
                <DnwldIcon
                  className={cn(styles.button_icon, "hideOnDesktop")}
                />
                <span className="hideOnMobile">
                  {t(`downloadStatement.title`)}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <SetRoyaltyPeriodModal
        labelRef={labelRef}
        currency={ledgers?.currency}
        top={labelRef?.current?.getBoundingClientRect().top + 20}
        left={labelRef?.current?.getBoundingClientRect().left}
        client={singleClient?.data}
      />
    </>
  );
};
export default LedgerBlock;
