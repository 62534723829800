import axios from "axios";

import { oktaAuth } from "../lib/oktaAuth";

/**
 * Main axios instance
 */
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  const accessToken = oktaAuth.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  const email =
    localStorage.getItem("impersonateEmail") ??
    localStorage.getItem("profileEmail");
  if (email) {
    config.headers["X-Email"] = email;
  }
  return config;
});

export { axiosInstance };
