import React from "react";
import { IDatasource } from "ag-grid-community";
import { IGetRowsParams } from "ag-grid-community/dist/lib/interfaces/iDatasource";
import axios from "axios";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./UserManagement.module.scss";

import GridPlaceholderLoader from "../../components/shared/GridPlaceholder";
import AssociatedUsersColumn from "../../components/shared/TableColumns/AssociatedUsersColumn";
import ClientNameColumn from "../../components/shared/TableColumns/ClientNameColumn";
import EmailColumn from "../../components/shared/TableColumns/EmailColumn";
import PayeeCodeColumn from "../../components/shared/TableColumns/PayeeCodeColumn";
import PayeeNameColumn from "../../components/shared/TableColumns/PayeeNameColumn";
import TitleColumn from "../../components/shared/TableColumns/TitleColumn";
import {
  UserRole,
  UserStatus
} from "../../components/userManagement/api/users";
import Icons from "../../components/userManagement/Icons/Icons";
import { userTypes } from "../../configs/constants/innerRole";
import { oktaAuth } from "../../lib/oktaAuth";
import { jc } from "../../lib/styles";
import { getDateByUTCFormatNoTime } from "../../utils";
import { convertToPeriod } from "./utils";

const getRoleDisplayName = (role: UserRole | undefined): string => {
  switch (role) {
    case UserRole.ADMIN:
      return "Admin";
    // case UserRole.SENIOR_ADMIN:
    //   return "Senior Admin";
    case UserRole.SUPER_ADMIN:
      return "Super Admin";
    case UserRole.EXTERNAL_USER:
      return "Songwriter";
    default:
      return "";
  }
};

const getStatusDisplayName = (status: UserStatus | undefined): string => {
  switch (status) {
    case UserStatus.Active:
      return "Active";
    case UserStatus.Archived:
      return "Archived";
    case UserStatus.Inactive:
      return "Inactive";
    case UserStatus.Suspended:
      return "Suspended";
    case UserStatus.Registered:
      return "Registered";
    case UserStatus.Invited:
      return "Invited";
    case UserStatus.PENDING_REGISTERED:
      return "Pending Registered";
    default:
      return "";
  }
};

const generateTagClassName = (status: string) => {
  return jc(
    styles.tag,
    (typeof status === "undefined" || status === "") && `${styles.tag}`,
    status === "INVITED" && `${styles.tag_invited}`,
    status === "PENDING_REGISTERED" && `${styles.tag_invited}`,
    status === "REGISTERED" && `${styles.tag_registered}`,
    status === "ACTIVE" && `${styles.tag_active}`,
    status === "INACTIVE" && `${styles.tag_inactive}`,
    status === "SUSPENDED" && `${styles.tag_suspended}`
  );
};
const getTitleColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <TitleColumn data={data} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getEmailColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <EmailColumn data={data} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getAssocciatedusersColumn = ({ data }: any) => {
  if (Object.keys(data)?.length) {
    return <AssociatedUsersColumn user={data.user} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getLocationColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return (
      <div className="d-flex flex-col h-full justify-center">
        <div className="text-13">
          {(Object.keys(data).length && data.location) || ""}
        </div>
      </div>
    );
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getTypeColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    const mappedTypes = data.songwriterTypes
      ?.map((type: string) => {
        return userTypes[type];
      })
      .join(", ");
    return (
      <div className="d-flex flex-col h-full justify-center">
        <div className="text-13">{mappedTypes || ""}</div>
      </div>
    );
  } else {
    return <GridPlaceholderLoader />;
  }
};

const getRoleColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <div>{getRoleDisplayName(data.role)}</div>;
  } else return <GridPlaceholderLoader />;
};
const getStatusColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return (
      <div className={cn(styles.tag, generateTagClassName(data.status))}>
        {getStatusDisplayName(data.status)}
      </div>
    );
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getDateColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <div>{getDateByUTCFormatNoTime(data.modifiedAt)}</div>;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getLastActiveColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return (
      <div className={styles.capitalize}>
        {data.lastActive &&
          DateTime.fromJSDate(new Date(data.lastActive)).toRelative()}
      </div>
    );
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getModifiedByColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return data && data.modifiedBy ? (
      <div className={cn(styles.tag)}>{data.modifiedBy}</div>
    ) : (
      <div />
    );
  } else {
    return <GridPlaceholderLoader />;
  }
};

const getPayeeCodeColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <PayeeCodeColumn data={data} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getPayeeNameColumn = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <PayeeNameColumn data={data} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getAccountingPeriod = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <div>{convertToPeriod(data.accountingPeriod)}</div>;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getSettlementPeriod = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <div>{`${data.settlementPeriod} Days`}</div>;
  } else {
    return <GridPlaceholderLoader />;
  }
};
const getClientName = ({ data }: any) => {
  if (Object.keys(data).length) {
    return <ClientNameColumn data={data} />;
  } else {
    return <GridPlaceholderLoader />;
  }
};

export interface infiniteScrollProps {
  prefix: string;
  api: any;
}

export const generateTableData = (prefix: any, api: any, filter: any) => {
  const body = (params: IGetRowsParams) => {
    return {
      skip: params.startRow,
      take: params.endRow - params.startRow,
      ...(filter &&
        filter.length && {
          filter: { and: filter }
        })
    };
  };
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: function(params) {
      axios
        .post(`${prefix}api/users/fetch`, body(params), {
          headers: {
            authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        })
        .then(res => {
          const data = res.data.content;
          // setRowData(data);
          params.successCallback(data, res.data.total);
          // api.api.forEachNode(setRowData);
          // setRowData();
        });
      console.log("asking for " + params.startRow + " to " + params.endRow);
    }
  };
  api.api.setDatasource(dataSource);
};
const defaultOptions = (
  field: string | undefined,
  sortable: boolean,
  unSortIcon: boolean
) => {
  return {
    icons: Icons().icons,
    field: field,
    hide: false,
    sortable: sortable,
    sortingOrder: ["asc", "desc", null],
    unSortIcon: unSortIcon,
    comparator: () => 0
  };
};
export const activityGrid = () => {
  return {
    superAdminColumnDefs: [
      {
        headerName: "User Name",
        flex: 1.7,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 2,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "Location",
        flex: 1,
        cellRenderer: getLocationColumn,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "User Type",
        cellRenderer: getRoleColumn,
        flex: 1,
        ...defaultOptions("role", false, true)
      },
      {
        headerName: "Status",
        flex: 1,
        cellRenderer: getStatusColumn,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Modified",
        flex: 1,
        cellRenderer: getDateColumn,
        ...defaultOptions("modifiedAt", true, true)
      },
      {
        headerName: "Modified By",
        cellRenderer: getModifiedByColumn,
        flex: 1,
        ...defaultOptions("modifiedBy", true, true)
      }
    ],
    adminColumns: [
      {
        headerName: "User Name",
        flex: 1.7,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 2,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Activity",
        cellRenderer: getLastActiveColumn,
        flex: 1,
        ...defaultOptions("lastActive", true, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 1,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Modified",
        flex: 1,
        cellRenderer: getDateColumn,
        ...defaultOptions("modifiedAt", true, true)
      }
    ],
    superAdminQuickFilters: [
      "activeCount",
      "inactiveCount",
      "invitedCount",
      "registeredCount",
      "suspendedCount"
    ],
    adminQuickFilters: ["invitedCount", "registeredCount", "suspendedCount"]
  };
};
export const songWriterGrid = (status?: string) => {
  return {
    superAdminColumnDefs: [
      {
        headerComponent: "headerCheckBoxRender",
        width: 58,
        sortable: false,
        field: "checkbox",
        hide: status !== "registered" && status !== "suspended",
        unSortIcon: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerClass: "pl-15",
        rowDragManaged: false,
        resizable: false,
        rowDrag: false
      },
      {
        headerName: "User Name",
        flex: 1.6,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true),
        flex: 1.6
      },
      {
        headerName: "User Type",
        flex: 1,
        cellRenderer: getTypeColumn,
        ...defaultOptions("songwriterType", false, false)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Activity",
        flex: 0.8,
        cellRenderer: getLastActiveColumn,
        ...defaultOptions("lastActive", true, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 1,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Modified",
        cellRenderer: getDateColumn,
        flex: 0.7,
        ...defaultOptions("modifiedAt", true, true)
      },
      {
        headerName: "",
        cellRenderer: "getColAccent",
        // flex: 0.2,
        width: 48,
        sortable: false,
        hide: false,
        unSortIcon: true
      }
    ],
    superAdminQuickFilters: [
      "invitedCount",
      "registeredCount",
      "suspendedCount"
    ],
    adminColumns: [
      {
        headerComponent: "headerCheckBoxRender",
        width: 58,
        sortable: false,
        field: "checkbox",
        hide: status !== "registered" && status !== "suspended",
        unSortIcon: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerClass: "pl-15",
        rowDragManaged: false,
        resizable: false,
        rowDrag: false
      },
      {
        headerName: "User Name",
        flex: 1.7,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 2,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "User Type",
        flex: 1,
        cellRenderer: getTypeColumn,
        ...defaultOptions("songwriterType", false, false)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Activity",
        cellRenderer: getLastActiveColumn,
        flex: 1,
        ...defaultOptions("lastActive", true, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 1,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Modified",
        flex: 1,
        cellRenderer: getDateColumn,
        ...defaultOptions("modifiedAt", true, true)
      },
      {
        headerName: "",
        cellRenderer: "getColAccent",
        // flex: 0.2,
        width: 48,
        sortable: false,
        hide: false,
        unSortIcon: true
      }
    ]
  };
};
export const internalUsersGrid = (status?: string) => {
  return {
    superAdminColumnDefs: [
      {
        headerComponent: "headerCheckBoxRender",
        width: 58,
        // sortable: false,
        field: "checkbox",
        hide: !status,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerClass: "pl-15",
        rowDragManaged: false,
        resizable: false,
        rowDrag: false
      },
      {
        headerName: "User Name",
        flex: 1.2,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 2.2,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Role",
        cellRenderer: getRoleColumn,
        flex: 1,
        ...defaultOptions("role", false, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 0.8,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Modified",
        cellRenderer: getDateColumn,
        flex: 0.7,
        ...defaultOptions("modifiedAt", true, true)
      },
      {
        headerName: "Modified By",
        cellRenderer: getModifiedByColumn,
        flex: 1,
        ...defaultOptions("modifiedBy", true, true)
      },
      {
        headerName: "",
        cellRenderer: "getColAccent",
        width: 48,
        sortable: false,
        hide: false,
        unSortIcon: true
      }
    ],
    superAdminQuickFilters: ["activeCount"]
  };
};
export const archiveGrid = () => {
  return {
    superAdminColumnDefs: [
      {
        headerName: "User Name",
        flex: 1.3,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 1.8,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Role",
        cellRenderer: getRoleColumn,
        flex: 1,
        ...defaultOptions("role", false, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 0.8,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Archived",
        cellRenderer: getDateColumn,
        flex: 0.7,
        ...defaultOptions("modifiedAt", true, true)
      },
      {
        headerName: "Archived by",
        cellRenderer: getModifiedByColumn,
        flex: 1,
        ...defaultOptions("modifiedBy", true, true)
      },
      {
        headerName: "",
        cellRenderer: "getColAccent",
        // flex: 0.2,
        width: 48,
        sortable: false,
        hide: true,
        unSortIcon: true
      }
    ],
    superAdminQuickFilters: ["suspendedCount", "inactiveCount"],
    adminQuickFilters: ["suspendedCount"],
    adminColumns: [
      {
        headerName: "User Name",
        flex: 1.4,
        cellRenderer: getTitleColumn,
        ...defaultOptions("title", true, true)
      },
      {
        headerName: "Email",
        flex: 2,
        cellRenderer: getEmailColumn,
        ...defaultOptions("email", true, true)
      },
      {
        headerName: "Location",
        cellRenderer: getLocationColumn,
        flex: 1,
        ...defaultOptions("location", true, true)
      },
      {
        headerName: "Activity",
        cellRenderer: getLastActiveColumn,
        flex: 1,
        ...defaultOptions("lastActive", true, true)
      },
      {
        headerName: "Status",
        cellRenderer: getStatusColumn,
        flex: 1,
        ...defaultOptions("status", true, true)
      },
      {
        headerName: "Archived",
        flex: 1.5,
        cellRenderer: getDateColumn,
        ...defaultOptions("modifiedAt", true, true)
      }
    ]
  };
};

export const payeeCodeGrid = () => {
  return {
    columns: [
      {
        headerName: "User Email",
        flex: 1,
        cellRenderer: getAssocciatedusersColumn,
        ...defaultOptions("userEmail", false, true)
      },
      {
        headerName: "Client Name",
        flex: 1,
        cellRenderer: getClientName,
        ...defaultOptions("clientFullName", true, true)
      },
      {
        headerName: "Payee Account",
        flex: 1,
        cellRenderer: getPayeeNameColumn,
        ...defaultOptions("payeeAccount", true, true)
      },
      {
        headerName: "Payee Code",
        flex: 1,
        cellRenderer: getPayeeCodeColumn,
        ...defaultOptions("payeeCode", true, true)
      },
      {
        headerName: "Accounting Period",
        flex: 1,
        cellRenderer: getAccountingPeriod,
        ...defaultOptions("accountingPeriod", true, true)
      },
      {
        headerName: "Settlement Period",
        flex: 1,
        cellRenderer: getSettlementPeriod,
        ...defaultOptions("settlementPeriod", true, true)
      },
      {
        headerName: "Modified",
        cellRenderer: getDateColumn,
        flex: 0.7,
        ...defaultOptions("modifiedAt", true, true)
      }
    ],
    quickFilters: ["semiannuallyPayeeCounts", "quarterlyPayeeCounts"]
  };
};
export default {
  activityGrid,
  songWriterGrid,
  internalUsersGrid,
  archiveGrid,
  generateTableData
};
