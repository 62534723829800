import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import classNames from "classnames";

import styles from "./BackButton.module.scss";
import { ReactComponent as Cursor } from "../../../icons/cursor.svg";

import { BackButtonProps } from "./BackButton.types";

/**
 * Renders back button which navigates back through history
 * @param props React component properties
 * @returns JSX element
 */
const BackButton = (props: BackButtonProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  /**
   * Handles back button click
   */
  const onClick = (): void => {
    // Go back if there is a history, otherwise go to /home
    location.key === "default" ? navigate("/home") : navigate(-1);
  };

  return (
    <button
      className={classNames(styles.button, props.className)}
      onClick={onClick}
      type="button"
    >
      <Cursor />
      {t("buttons.back")}
    </button>
  );
};

export default BackButton;
