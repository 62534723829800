import React, { useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios from "axios";
import cn from "classnames";
import { DateTime } from "luxon";

import styles from "./SetDwnldCSVModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warning.svg";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import {
  bodySelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import LoadingCircle from "../../LoadingCircle";
import Button from "../../UiButton";

const SetDwnldCSVModal = () => {
  const body = useSelector(bodySelector);
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isSuspendList = Array.isArray(modal?.user);

  const handleClearState = useCallback(() => {
    setIsSuccess(false);
    setError("");
  }, []);

  const content = useMemo(() => {
    switch (modal.type) {
      case "csvDwnld": {
        return {
          title: "Download User Data",
          description: `A CSV file containing <b/>${modal?.user} user’s data </b> will be downloaded onto your computer.`,
          buttonTitle: "Download",
          successTitle: ""
        };
      }
      default: {
        return {
          title: "",
          description: "",
          buttonTitle: "",
          successTitle: ""
        };
      }
    }
  }, [modal]);

  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
    handleClearState();
  }, [SetOpenModalAction, handleClearState]);

  function downloadFile(fileName: string, urlData: any) {
    const aLink = document.createElement("a");
    aLink.href = urlData;
    aLink.download = fileName;
    document.body.appendChild(aLink);
    aLink.click();
    aLink.remove();
  }

  const handleClick = useCallback(() => {
    const requestBody = {
      ...body,
      skip: 0,
      take: modal?.user
    };
    setIsLoading(true);
    axios
      .post(`/api/users/export/CSV`, requestBody, {
        headers: {
          authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: "*/*"
        }
      })
      .then(async (response: any) => {
        setIsLoading(false);
        toggleModal();
        await downloadFile(
          `${modal.fileName}-${DateTime.now().toLocaleString(
            DateTime.DATE_SHORT
          )}`,
          "data:text/csv;charset=UTF-8," + encodeURIComponent(response.data)
        );
      });
  }, [body, modal.fileName, modal?.user, toggleModal]);
  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "csvDwnld"}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal, {
          [styles.success]: isSuccess,
          [styles.suspendModal]: isSuspendList
        })}
        overlayClassName={styles.myoverlay}
      >
        <>
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
          <h2 className={styles.title}>{content.title}</h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
          <div className={styles.buttonWrap}>
            {isLoading && <LoadingCircle className={styles.loadingCircle} />}
            {error && (
              <div className={styles.userError}>
                <WarningIcon className={styles.warning} />
                {error}
              </div>
            )}
            <Button
              className={styles.button}
              onClick={handleClick}
              loading={isLoading}
              disabled={isLoading}
            >
              {content.buttonTitle}
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default SetDwnldCSVModal;
