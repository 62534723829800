import axios from "axios";
import { DateTime } from "luxon";

import { oktaAuth } from "../../../lib/oktaAuth";
import { downloadFile } from "../../../utils/file";

interface SortField {
  field: string;
  order: "asc" | "desc";
}

interface FilterOperation {
  name: string;
  operation: string;
  value?: any;
  values?: any[];
}

interface Filter {
  and: FilterOperation[];
}

interface DownloadData {
  skip: number;
  take: number;
  sort: SortField[];
  filter: Filter;
}

const generateFilterOperation = (
  name: string,
  operation: string,
  value?: any,
  values?: any[]
): FilterOperation => ({ name, operation, value, values });

const generateDownloadData = (
  batchId: any,
  isArray?: boolean
): DownloadData => ({
  skip: 0,
  take: 100000,
  sort: [{ field: "uploadedAt", order: "asc" }],
  filter: {
    and: [
      generateFilterOperation(
        "batchId",
        isArray ? "in" : "eq",
        isArray ? undefined : batchId,
        isArray ? batchId : undefined
      )
    ]
  }
});

const generateDownloadReportData = (
  userName: any,
  downloadDateRangeModal: any
): DownloadData => {
  const startDate =
    downloadDateRangeModal &&
    DateTime.fromJSDate(new Date(downloadDateRangeModal.startDate)).toFormat(
      "yyyy-MM-dd"
    );

  const endDate =
    downloadDateRangeModal &&
    DateTime.fromJSDate(new Date(downloadDateRangeModal.endDate)).toFormat(
      "yyyy-MM-dd"
    ) + "T23:59:59";
  const filter: Filter = { and: [] };
  downloadDateRangeModal &&
    filter.and.push(
      generateFilterOperation("uploadedAt", "ge", startDate),
      generateFilterOperation("uploadedAt", "le", endDate)
    );

  if (userName.length === 1) {
    filter?.and?.push(
      generateFilterOperation("uploaderUserEmail", "eq", userName[0])
    );
  } else if (userName.length > 1) {
    filter?.and?.push(
      generateFilterOperation("uploaderUserEmail", "in", undefined, userName)
    );
  }

  return {
    skip: 0,
    take: 100000,
    sort: [{ field: "uploadedAt", order: "asc" }],
    filter
  };
};

const handleFileDownload = async (
  apiEndpoint: string,
  downloadData: DownloadData
) => {
  try {
    const response = await axios.post(apiEndpoint, downloadData, {
      headers: {
        authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        Accept: "*/*",
        ContentType: `application/pdf`
      }
    });
    const fileName = response.data.fileName.includes(".csv")
      ? response.data.fileName
      : response.data.fileName + ".csv";
    await downloadFile(
      fileName,
      "data:text/csv;charset=UTF-8," +
        encodeURIComponent(response.data.csvContent)
    );
  } catch (error) {
    console.error("Error during file download:", error);
  }
};

const handleClickDownload = async (
  batchId: any,
  initialTableType: any,
  isArray?: boolean
) => {
  const API_ENDPOINT = `/api/cms/${initialTableType}/export/CSV`;
  const downloadData: DownloadData = generateDownloadData(batchId, isArray);
  await handleFileDownload(API_ENDPOINT, downloadData);
};

const handleDownloadReport = async (
  downloadDateRangeModal: any,
  userName: any,
  initialTableType: any
) => {
  const API_ENDPOINT = `/api/cms/${initialTableType}/export/CSV`;

  const downloadData: DownloadData = generateDownloadReportData(
    userName,
    downloadDateRangeModal
  );
  await handleFileDownload(API_ENDPOINT, downloadData);
};

export default { handleClickDownload, handleDownloadReport };
